import { Text, useTheme } from "@ui-kitten/components";
import { Platform, View } from "react-native";

import { Icon, IconProps } from "./Icon";

interface IconHeaderProps extends IconProps {
  text: string;
}

export const IconHeader = ({ icon, text }: IconHeaderProps) => {
  const theme = useTheme();
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginTop: 12,
        marginBottom: 24,
      }}
    >
      <Icon
        icon={icon}
        size={Platform.OS === "web" ? 28 : 24}
        color={theme["color-primary-300"]}
      />
      <View style={{ marginHorizontal: 12 }} />
      <Text category={Platform.OS === "web" ? "h6" : "p1"}>{text}</Text>
    </View>
  );
};
