import AsyncStorage from "@react-native-async-storage/async-storage";
import { createAsyncStoragePersister } from "@tanstack/query-async-storage-persister";
import { focusManager } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { ReactNode } from "react";
import { AppStateStatus, Platform } from "react-native";

import pkg from "../../package.json";
import { ApiProvider, models } from "../api";
import { getConfig } from "../config";
import { useAppState } from "../hooks/useAppState";
import { useOnlineManager } from "../hooks/useOnlineManager";
import { prefixKey, useStorage } from "../lib/storage";
import { queryClient } from "../queryClient";

const { api } = getConfig();

function onAppStateChange(status: AppStateStatus) {
  if (Platform.OS !== "web") {
    focusManager.setFocused(status === "active");
  }
}

const persister = createAsyncStoragePersister({
  key: prefixKey("cache"),
  storage: AsyncStorage,
});

export const QueryClientProvider = ({ children }: { children: ReactNode }) => {
  useOnlineManager();
  useAppState(onAppStateChange);
  const [token] = useStorage<string>("token");

  return (
    <PersistQueryClientProvider
      client={queryClient}
      // @ts-ignore
      persistOptions={{ persister, buster: pkg.version }}
      onSuccess={() => {
        queryClient.resumePausedMutations().then(() => {
          queryClient.invalidateQueries();
        });
      }}
    >
      <ApiProvider
        baseUrl={api}
        models={models}
        token={token}
        client={queryClient}
      >
        {children}
      </ApiProvider>
    </PersistQueryClientProvider>
  );
};
