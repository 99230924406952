import { faPercentage, faFish } from "@fortawesome/pro-regular-svg-icons";
import { Layout, Text } from "@ui-kitten/components";
import { Platform, View } from "react-native";

import { useApi } from "../../api";
import {
  SelectionModal,
  TouchableHighlight,
  IconHeader,
} from "../../components";
import { useAuth } from "../../context/authProvider";
import { useDefaultSelections } from "../../context/defaultSelectionsProvider";
import { useModal, Modal } from "../../hooks/useModal";

export const DefaultsScreen = () => {
  const api = useApi();
  const { tenant } = useAuth();
  const { data: allDeductions = [] } = api.Deduction.all();
  const { data: { results: allSpecies = [] } = {} } = api.Species.search({
    fields: [{ name: "active", is: "true" }],
    limit: 9999,
  });

  const {
    defaultSpecies,
    defaultDeduction,
    setDefaultSpecies,
    setDefaultDeduction,
  } = useDefaultSelections();

  const {
    toggle: toggleSpeciesSelectionModal,
    modalProps: speciesSelectionModalProps,
  } = useModal({
    title: `Select ${tenant?.account?.tunaFunctionality ? "Tuna" : "Species"}`,
  });

  const {
    toggle: toggleDeductionSelectionModal,
    modalProps: deductionSelectionModalProps,
  } = useModal({
    title: `Select Deduction`,
  });

  const handleSpeciesSelection = ([speciesId]: (string | number)[]) => {
    const species = allSpecies.find(
      ({ id }) => String(id) === String(speciesId)
    );
    if (!species) {
      return;
    }
    setDefaultSpecies(species);
    toggleSpeciesSelectionModal();
  };

  const handleDeductionSelection = ([deductionId]: (string | number)[]) => {
    const deduction = allDeductions.find(
      ({ itemId }) => Number(itemId) === Number(deductionId)
    );
    if (!deduction) {
      return;
    }
    setDefaultDeduction(deduction);
    toggleDeductionSelectionModal();
  };

  return (
    <>
      <IconHeader
        icon={faFish}
        text={
          tenant?.account?.tunaFunctionality
            ? "Default Tuna"
            : "Default Species"
        }
      />

      <Layout
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 24,
        }}
      >
        <TouchableHighlight
          onPress={() => {
            toggleSpeciesSelectionModal();
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            padding: 24,
          }}
        >
          <Text category="h5" numberOfLines={5}>
            {defaultSpecies.id
              ? defaultSpecies.name
              : "No default species selected"}
          </Text>
        </TouchableHighlight>
        {/* <Button
              appearance="ghost"
              status="danger"
              accessoryLeft={(props) => <Icon {...props} icon={faTrash} />}
              onPress={() => removeDeduction(deduction)}
            /> */}
      </Layout>

      <IconHeader icon={faPercentage} text="Deductions" />

      <Layout
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TouchableHighlight
          onPress={() => {
            toggleDeductionSelectionModal();
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            padding: 24,
          }}
        >
          <>
            {defaultDeduction.id > 0 && (
              <Text category="label" style={{ marginBottom: 12 }}>
                {defaultDeduction.name ?? ""}
              </Text>
            )}
            <Text
              category={Platform.OS === "web" ? "h5" : "p1"}
              numberOfLines={4}
            >
              {defaultDeduction.id
                ? defaultDeduction.description
                : "No default deduction selected"}
            </Text>
          </>
        </TouchableHighlight>
        {/* <Button
              appearance="ghost"
              status="danger"
              accessoryLeft={(props) => <Icon {...props} icon={faTrash} />}
              onPress={() => removeDeduction(deduction)}
            /> */}
      </Layout>

      <Modal {...speciesSelectionModalProps}>
        <SelectionModal
          selectKey="id"
          onSelect={handleSpeciesSelection}
          items={allSpecies
            .filter((species) => !!species.enabled)
            .filter(
              (species) => !species.name.toLowerCase().includes("price change")
            )}
          renderItem={(species) => (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 12,
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Text category="s1">{species.name}</Text>
              </View>
            </View>
          )}
        />
      </Modal>
      <Modal {...deductionSelectionModalProps}>
        <SelectionModal
          selectKey="itemId"
          onSelect={handleDeductionSelection}
          items={allDeductions}
          renderItem={(deduction) => (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 12,
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Text category="s1">{deduction.itemName}</Text>

                {deduction.itemDescription && (
                  <Text category="s2">{deduction.itemDescription}</Text>
                )}
              </View>
            </View>
          )}
        />
      </Modal>
    </>
  );
};
