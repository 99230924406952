import {
  faCalendar,
  faDownload,
  faFilter,
  faList,
  faPerson,
  faShoppingBag,
  faSync,
} from "@fortawesome/pro-regular-svg-icons";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useQueryClient } from "@tanstack/react-query";
import { request } from "@tracktile/axiom";
import {
  Layout,
  Text,
  Card,
  Button,
  RangeDatepicker,
  Datepicker,
  CalendarRange,
  OverflowMenu,
  IndexPath,
  Spinner,
  MenuItem,
  StyleService,
  useStyleSheet,
  useTheme,
} from "@ui-kitten/components";
import moment, { Moment } from "moment";
import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { FlatList, Pressable, Platform, ScrollView } from "react-native";
import { Row, Rows } from "react-native-table-component-2";

import { OrdersStackParamsList } from "./types";
import {
  useApi,
  RSBaitOrder,
  RSBaitOrderItem,
  RSBaitFisher,
  User,
} from "../../api";
import {
  TopNavigation,
  BaitOrderCard,
  Select,
  FisherSelectionModal,
  CenteredContainer,
  Icon,
} from "../../components";
import { getConfig } from "../../config";
import { useAuth } from "../../context/authProvider";
import {
  useBaitLocation,
  BaitLocation,
} from "../../context/baitLocationProvider";
import { useModal, Modal } from "../../hooks";
import { dateRangeSelectionMap } from "../../lib/date-range";
import { useStorage } from "../../lib/storage";

type DateRangeSelectionOption =
  | "today"
  | "yesterday"
  | "last_7_days"
  | "custom_day"
  | "custom_range";

const DateRangeLabelMap: Record<DateRangeSelectionOption, string> = {
  today: "Today",
  yesterday: "Yesterday",
  last_7_days: "Last 7 Days",
  custom_day: "Single Day",
  custom_range: "Day Range",
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    padding: 16,
    paddingTop: 30,
  },
  head: {
    height: 48,
    fontWeight: "bold",
    backgroundColor: "background-basic-color-2",
  },
  summaryRow: {
    height: 48,
    backgroundColor: "background-basic-color-3",
    borderBottom: "1px solid #ccc",
  },
  wrapper: { flexDirection: "row" },
  title: { flex: 1, backgroundColor: "#f6f8fa" },
  noOrdersText: { color: "color-basic-transparent-700" },
  row: {
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: "background-basic-color-4",
  },
  text: {
    fontSize: 18,
    textAlign: "left",
    marginLeft: 18,
    color: "text-basic-color",
  },
});

function buildCountMapBy(
  items: RSBaitOrderItem[],
  itemNameFn: (item: RSBaitOrderItem) => string
) {
  return items.reduce(
    (acc, item) => {
      const [group, child] = [itemNameFn(item), item.quantity.counterPointId];
      return {
        ...acc,
        [group]: {
          ...acc[group],
          [child]: (acc?.[group]?.[child] ?? 0) + item.count,
        },
      };
    },
    {} as Record<string, Record<string, number>>
  );
}

interface RowDataObject {
  groupName: string;
  itemName: string;
  counterPointId: string;
  count: number;
}

type RowDataArray = (string | number)[];

function toRowData(
  map: Record<string, RSBaitOrderItem[]>,
  itemNameFn?: (item: RSBaitOrderItem) => string
): RowDataArray[] {
  const nameFn =
    typeof itemNameFn === "function"
      ? itemNameFn
      : (item: RSBaitOrderItem) => {
          return `${item.quantity.value}${item.quantity.unit} ${item.species.name}`;
        };

  return Object.entries(
    Object.entries(map).reduce(
      (acc, [groupName, items]) => ({
        ...acc,
        [groupName]: buildCountMapBy(items, nameFn),
      }),
      {} as Record<string, Record<string, Record<string, number>>>
    )
  )
    .reduce(
      (acc, [groupName, itemIdCountMap]) => [
        ...acc,
        ...Object.entries(itemIdCountMap).reduce(
          (acc2, [itemName, cpIdCountMap]) => [
            ...acc2,
            ...Object.entries(cpIdCountMap).reduce(
              (acc3, [counterPointId, count]) => [
                ...acc3,
                { groupName, itemName, counterPointId, count },
              ],
              [] as RowDataObject[]
            ),
          ],
          [] as RowDataObject[]
        ),
      ],
      [] as RowDataObject[]
    )
    .map(
      ({ groupName, itemName, counterPointId, count }): RowDataArray => [
        groupName,
        itemName,
        counterPointId,
        count,
      ]
    )
    .sort(([a], [b]) => (a > b ? 1 : a < b ? -1 : 0));
}

// eslint-disable-next-line no-empty-pattern
export const BaitOrdersScreen = () => {
  const api = useApi();
  const { user } = useAuth();
  const styles = useStyleSheet(themedStyles);
  const navigation =
    useNavigation<NativeStackNavigationProp<OrdersStackParamsList>>();
  const { location, locations } = useBaitLocation();
  const refreshIconRef = useRef<typeof Icon>(null);
  const [isSyncingToCounterPoint, setIsSyncingToCounterPoint] =
    useState<boolean>(false);
  const [token] = useStorage<string>("token");

  const [summaryGroupMode, setSummaryGroupMode] = useState<
    "Fisher" | "Species" | "Species Price" | "Location" | "User"
  >("Species Price");

  const [showSummaryGroupSelector, setShowSummaryGroupSelector] =
    useState<boolean>(false);

  const [dateRange, setDateRange] = useState<CalendarRange<Date>>({
    startDate: new Date(),
    endDate: new Date(),
  });

  const [dateSelectionMode, setDateSelectionMode] =
    useState<DateRangeSelectionOption>("today");
  const [showSummary, setShowSummary] = useState<boolean>(false);

  const { data: users = [] } = api.User.all();
  const { data: fishers = [] } = api.RSBaitFisher.all();
  const theme = useTheme();

  const fromMs = moment(dateRange.startDate).startOf("day").valueOf();
  const untilMs = moment(dateRange.endDate).endOf("day").valueOf();

  const [locationFilterSelections, setLocationFilterSelections] =
    useState<BaitLocation[]>(locations);

  const [userFilterSelections, setUserFilterSelections] = useState<string[]>(
    users.map(({ email }) => email)
  );

  const [fisherSelection, setFisherSelection] = useState<RSBaitFisher[]>([]);

  const {
    data: { results: orders = [] } = { results: [] },
    isFetching: isFetchingOrders,
    isLoading: isLoadingOrders,
    status,
    refetch: refetchOrders,
    error,
  } = api.RSBaitOrder.search({
    limit: 9999,
    fields: [
      ...(Number.isSafeInteger(fromMs)
        ? [{ name: "from", is: `${fromMs}` }]
        : []),
      ...(Number.isSafeInteger(untilMs)
        ? [{ name: "until", is: `${untilMs}` }]
        : []),
      {
        name: "locations",
        isOneOf:
          Platform.OS === "web"
            ? locationFilterSelections.map(({ key }) => key.toLowerCase())
            : [location?.key?.toLowerCase()],
      },
      {
        name: "users",
        isOneOf: Platform.OS === "web" ? userFilterSelections : [user.email],
      },
      {
        name: "fishers",
        isOneOf: fisherSelection.map(({ id }) => id),
      },
    ],
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.setQueryData(["baitOrders"], () => orders);
  }, [orders, queryClient]);

  const {
    modalProps: fisherSelectionModalProps,
    toggle: toggleFisherSelectionModal,
  } = useModal({
    title: "Selected Fishers",
  });

  // useEffect(() => {
  //   const animate: () => unknown = () =>
  //     refreshIconRef?.current?.startAnimation(() => animate());
  //   animate();
  // }, [isFetchingOrders]);

  useFocusEffect(
    useCallback(() => {
      refetchOrders();
    }, [])
  );

  // const userCompareStr = (user?: User) => `${user?.email}${user?.firstName}`;

  const filteredOrders = orders;
  // const filteredOrders = useMemo(
  //   () =>
  //     orders
  // .filter((order) => {
  //   return (
  //     Platform.OS === "web" ||
  //     userCompareStr(order.user) === userCompareStr(user)
  //   );
  // })
  // .filter(({ locationKey }) => {
  //   if (Platform.OS !== "web") {
  //     return location?.key === locationKey;
  //   }
  //   return true;
  // })
  // .filter(({ locationKey, user }) => {
  //   if (Platform.OS === "web") {
  //     return locationFilterSelections
  //       .map(({ key }) => key.toLowerCase())
  //       .includes(locationKey.toLowerCase());
  //   }
  //   return true;
  // })
  // .filter(({ user }) => {
  //   if (
  //     Platform.OS === "web" &&
  //     userFilterSelections.length > 0 &&
  //     typeof user.email === "string"
  //   ) {
  //     return userFilterSelections.includes(user.email);
  //   }
  //   return true;
  // })
  //       .filter(({ fisher }) => {
  //         return (
  //           fisherSelection.length === 0 ||
  //           fisherSelection
  //             .map(({ counterPointId }) => counterPointId)
  //             .includes(fisher?.counterPointId ?? 0)
  //         );
  //       }),
  //   [
  //     orders,
  //     dateSelectionMode,
  //     dateRange.startDate,
  //     dateRange.endDate,
  //     fisherSelection,
  //     location?.key,
  //     locationFilterSelections,
  //     userFilterSelections,
  //   ]
  // );

  // console.log("filtered orders", filteredOrders.length);

  const toName = ({
    companyName,
    firstName,
    lastName,
  }: Partial<RSBaitFisher>) => {
    if (companyName) {
      return companyName;
    }
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    return `${firstName}`;
  };

  const fisherSummaryData = useMemo(
    () =>
      toRowData(
        filteredOrders.reduce(
          (acc, { fisher, items = [] }) => {
            return {
              ...acc,
              ...(fisher
                ? {
                    [fisher?.counterPointId]: [
                      ...(acc[fisher?.counterPointId] ?? []),
                      ...items,
                    ],
                  }
                : {}),
            };
          },
          {} as Record<string, RSBaitOrderItem[]>
        )
      )
        .filter(([cpId]) => {
          return cpId !== "undefined";
        })
        .map(([cpId, ...rest]) => {
          const fisher = fishers.find(
            ({ counterPointId }) => counterPointId === cpId
          );
          if (!fisher) {
            return [cpId, "Unknown Fisher", ...rest];
          }
          return [cpId, toName(fisher), ...rest];
        })
        .sort(([, , , a], [, , , b]) =>
          `${a}` > `${b}` ? 1 : `${a}` < `${b}` ? -1 : 0
        ),
    [filteredOrders]
  );

  const speciesSummaryData = useMemo(
    () =>
      toRowData(
        filteredOrders
          .map(({ items }) => items)
          .reduce((acc, items) => [...acc, ...items], [])
          .reduce(
            (acc, item) => ({
              ...acc,
              [item.species.name]: [...(acc[item.species.name] ?? []), item],
            }),
            {} as Record<string, RSBaitOrderItem[]>
          ),
        (item: RSBaitOrderItem) => `${item.quantity.value}${item.quantity.unit}`
      ).sort(([, , a], [, , b]) =>
        `${a}` > `${b}` ? 1 : `${a}` < `${b}` ? -1 : 0
      ),
    [filteredOrders]
  );

  const speciesPriceSummaryData = useMemo(() => {
    // Group filtered orders into a map where the key is a the species name, quantity value and unit
    // and the value is an array of the items
    const speciesItemMap = filteredOrders
      .map(({ items, fisher, locationKey, date }) =>
        items.map((item) => ({ ...item, fisher, locationKey, date }))
      )
      .reduce((acc, items) => [...acc, ...items], [])
      .reduce(
        (acc, item) => ({
          ...acc,
          [`${item.species.name} ${item.quantity.value}${item.quantity.unit}`]:
            [
              ...(acc[
                `${item.species.name} ${item.quantity.value}${item.quantity.unit}`
              ] ?? []),
              item,
            ],
        }),
        {} as Record<
          string,
          (RSBaitOrderItem & {
            date: number;
            locationKey: string;
            fisher?: {
              companyName?: string | undefined;
              id: string;
              enabled: boolean;
              counterPointId: number;
              firstName: string;
              lastName: string;
            };
          })[]
        >
      );

    // Group filtered orders into a map where the key is a the species name, quantiy value and unit
    // and value is the num of units sold for that species and quantity
    const speciesCountMap = Object.entries(speciesItemMap).reduce(
      (acc, [speciesName, items]) => ({
        ...acc,
        [speciesName]: items.reduce(
          (acc, item) => acc + item.count,

          0
        ),
      }),
      {} as Record<string, number>
    );

    const groups: {
      rows: [
        string, // Datetime
        string, // Location
        string, // Fisher CounterPoint ID
        string, // Fisher Company
        string, // Species Name + Value + Unit
        number, // Count Sold
        string, // Species CounterPoint ID
      ][];
      totals: {
        perUnit: string;
        total: string;
      };
    }[] = [];

    Object.entries(speciesItemMap).forEach(([speciesName, items]) => {
      const rows: [
        string, // Datetime
        string, // Location
        string, // Fisher CounterPoint ID
        string, // Fisher Company
        string, // Species Name + Value + Unit
        number, // Count Sold
        string, // Species CounterPoint ID
      ][] = [];
      let total = 0;
      const perUnit =
        items.find(({ quantity }) => quantity.price)?.quantity?.price ?? 0;
      items.forEach((item) => {
        total +=
          item.count *
          (typeof item.quantity.price !== "undefined"
            ? item.quantity.price
            : 0);

        rows.push([
          moment(item.date).format("YYYY-MM-DD"),
          item.locationKey,
          item.fisher?.counterPointId?.toString() ?? "N/A",
          item.fisher?.companyName ?? "N/A",
          speciesName,
          speciesCountMap[speciesName],
          item.quantity.counterPointId,
        ]);
      });
      groups.push({
        rows,
        totals: { perUnit: perUnit.toString(), total: total.toString() },
      });
    });
    return groups;
  }, [filteredOrders]);

  const locationSummaryData = useMemo(() => {
    const locationItemMap = filteredOrders.reduce(
      (acc, { locationKey, items }) => ({
        ...acc,
        [locationKey]: [...(acc[locationKey] ?? []), ...items],
      }),
      {} as Record<string, RSBaitOrderItem[]>
    );
    return toRowData(locationItemMap);
  }, [filteredOrders]);

  const userSummaryData = useMemo(() => {
    const userItemMap = filteredOrders.reduce(
      (acc, { user, items = [] }) => {
        const username = `${user.firstName} ${user.lastName}`;
        return { ...acc, [username]: [...(acc[username] ?? []), ...items] };
      },
      {} as Record<string, RSBaitOrderItem[]>
    );

    return toRowData(userItemMap);
  }, [filteredOrders]);

  const handleDateSelectionModeChanged = ([
    mode,
  ]: DateRangeSelectionOption[]) => {
    const value = dateRangeSelectionMap()[mode];

    setDateRange({
      startDate: value.startDate.toDate(),
      endDate: value.endDate.toDate(),
    });

    if (mode === "custom_day") {
      // singleDatePickerRef.current?.show();
      singleDatePickerRef.current?.focus();
    }
    if (mode === "custom_range") {
      // dateRangePickerRef.current?.show();
      dateRangePickerRef.current?.focus();
    }

    setDateSelectionMode(mode);
  };

  const handleFisherSelectionChanged = (
    counterPointIds: (string | number)[]
  ) => {
    setFisherSelection(
      fishers.filter(({ counterPointId }) =>
        counterPointIds.includes(counterPointId)
      )
    );
    toggleFisherSelectionModal();
  };

  const singleDatePickerRef = useRef<Datepicker<Date>>(null);
  const dateRangePickerRef = useRef<RangeDatepicker<Date>>(null);

  function buildAndDownloadCsv<T>(
    data: T[],
    mapper: (data: T) => unknown[],
    columnTitles: string[] = []
  ) {
    // Build CSV contents
    let csvContent = "";
    csvContent += columnTitles.join(",") + "\r\n";

    for (const item of data) {
      const mapped = mapper(item);
      csvContent += mapped.join(",") + "\r\n";
    }

    // Encode and download
    const csv = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.setAttribute("href", URL.createObjectURL(csv));
    link.setAttribute(
      "download",
      `${moment(dateRange.startDate).format("YYYY-MM-DD")}_To_${moment(
        dateRange.endDate
      ).format("YYYY-MM-DD")}_Orders.csv`
    );
    document.body.appendChild(link);
    link.click();
  }

  const handleSummaryExportToCsv = () => {
    const headersByMode: Record<
      "Fisher" | "Species" | "Species Price" | "Location" | "User",
      string[]
    > = {
      Fisher: ["Fisher Id", "Fisher Name", "Item", "ID", "Count"],
      Species: ["Species", "Item", "ID", "Count"],
      Location: ["Location", "Item", "ID", "Count"],
      User: ["User", "Item", "ID", "Count"],
      "Species Price": [],
    };
    const dataByMode: Record<
      "Fisher" | "Species" | "Species Price" | "Location" | "User",
      RowDataArray[]
    > = {
      Fisher: fisherSummaryData,
      Species: speciesSummaryData,
      Location: locationSummaryData,
      User: userSummaryData,
      "Species Price": [],
    };

    let csvContent = "";
    csvContent += headersByMode[summaryGroupMode].join(",") + "\r\n";
    for (const row of dataByMode[summaryGroupMode]) {
      csvContent += row.join(",") + "\r\n";
    }

    if (Platform.OS === "web") {
      const csv = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      link.setAttribute(
        "download",
        `${moment(dateRange.startDate).format("YYYY-MM-DD")}_To_${moment(
          dateRange.endDate
        ).format("YYYY-MM-DD")}_By_${summaryGroupMode}.csv`
      );
      link.setAttribute("href", URL.createObjectURL(csv));
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleOrdersExportToCsv = () => {
    const itemOrders = filteredOrders.reduce(
      (acc, { items, ...order }) => [
        ...acc,
        ...items.map((item) => ({ ...order, items: [item] })),
      ],
      [] as RSBaitOrder[]
    );

    buildAndDownloadCsv(
      itemOrders,
      (order: RSBaitOrder) => [
        moment(order.date).format("YYY-MM-DD hh:mm:ssA"),
        order.locationKey,
        order.fisher?.counterPointId,
        order.fisher?.firstName,
        order.fisher?.lastName,
        order.fisher?.companyName,
        `${order.items[0].quantity.value} ${order.items[0].quantity.unit} ${order.items[0].species.name}`,
        order.items[0].count,
        order.items[0].quantity.counterPointId,
      ],
      [
        "Created",
        "Location",
        "Fisher CP ID",
        "First Name",
        "Last Name",
        "Company Name",
        "Item Name",
        "Number Sold",
        "Item Counter Point ID",
      ]
    );
  };

  const syncBaitOrderIdToCounterPoint = async (id: string) => {
    return request(`${getConfig().api}/royalstar/bait/orders/${id}/submit`, {
      method: "POST",
      token,
    });
  };

  const handleSendToCounterPoint = async () => {
    setIsSyncingToCounterPoint(true);

    const orderIds = filteredOrders
      .filter((order) => !order.syncedToCounterPoint)
      .map((order) => order.id);

    async function promiseAllInBatches<T>(
      task: (id: string) => Promise<T>,
      items: string[],
      batchSize: number
    ) {
      let position = 0;
      let results = [] as T[];
      while (position < items.length) {
        const itemsForBatch = items.slice(position, position + batchSize);
        results = [
          ...results,
          ...(await Promise.all(itemsForBatch.map((item) => task(item)))),
        ];
        position += batchSize;
      }
      return results;
    }

    try {
      await promiseAllInBatches(syncBaitOrderIdToCounterPoint, orderIds, 1);
    } catch (err) {
      console.error(err);
      alert("One or more of the orders were not accepted by Counter Point.");
    }

    setIsSyncingToCounterPoint(false);
    refetchOrders();
  };

  const handleNewOrder = () => {
    if (!location && Platform.OS !== "web") {
      navigation.navigate("Change Location" as any);
    } else {
      navigation.navigate("Order" as any);
    }
  };

  return (
    <Layout level="4" style={{ flex: 1 }}>
      <TopNavigation
        navigation={navigation}
        title={Platform.OS === "web" ? "Orders" : location?.name ?? "Orders"}
        showMenuAction
      />
      <Card
        style={{
          paddingHorizontal: 0,
          minHeight: 86,
        }}
      >
        <CenteredContainer>
          <Layout
            style={{
              padding: 0,
              flexDirection: "row",
            }}
          >
            <Layout style={{ flexDirection: "row", flex: 1 }}>
              <Layout style={{ flex: 1, maxWidth: 200, flexDirection: "row" }}>
                <Select<DateRangeSelectionOption>
                  accessoryLeft={<Icon icon={faCalendar} color="#ddd" />}
                  options={Object.entries(DateRangeLabelMap).map(
                    ([value, label]) => ({
                      label,
                      value: value as DateRangeSelectionOption,
                    })
                  )}
                  onSelect={handleDateSelectionModeChanged}
                  value={dateSelectionMode}
                />
              </Layout>
              {dateSelectionMode === "custom_day" && (
                <Datepicker
                  ref={singleDatePickerRef}
                  status="primary"
                  size="large"
                  date={dateRange.startDate}
                  min={new Date(2020, 0, 1)}
                  max={new Date()}
                  onSelect={(date) => {
                    setDateRange({
                      startDate: date,
                      endDate: date,
                    });
                  }}
                />
              )}
              {dateSelectionMode === "custom_range" && (
                <RangeDatepicker
                  ref={dateRangePickerRef}
                  range={dateRange}
                  status="primary"
                  size="large"
                  min={new Date(2020, 0, 1)}
                  max={new Date()}
                  boundingMonth={false}
                  onSelect={(nextRange) => {
                    setDateRange(nextRange);
                  }}
                />
              )}
            </Layout>

            <Layout
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                appearance="ghost"
                size="large"
                onPress={() => toggleFisherSelectionModal()}
                accessoryLeft={(props) => <Icon {...props} icon={faPerson} />}
              >
                {(fisherSelection.length === 0 && "Filter Fishers") ||
                  `Viewing ${fisherSelection.length} Fishers`}
              </Button>

              {Platform.OS !== "web" && showSummary && (
                <OverflowMenu
                  anchor={() => (
                    <Button
                      appearance="ghost"
                      size="large"
                      accessoryLeft={(props) => (
                        <Icon {...props} icon={faFilter} />
                      )}
                      onPress={() =>
                        setShowSummaryGroupSelector(!showSummaryGroupSelector)
                      }
                    >
                      {`By ${summaryGroupMode}`}
                    </Button>
                  )}
                  visible={showSummaryGroupSelector}
                  selectedIndex={
                    new IndexPath(
                      ["Fisher", "Species"].indexOf(summaryGroupMode)
                    )
                  }
                  onSelect={(path: IndexPath) => {
                    setSummaryGroupMode(
                      ["Fisher", "Species"][path.row] as "Fisher" | "Species"
                    );
                    setShowSummaryGroupSelector(false);
                  }}
                  onBackdropPress={() => setShowSummaryGroupSelector(false)}
                >
                  <MenuItem title="By Fisher" />
                  <MenuItem title="By Species" />
                  <MenuItem title="By Species Price" />
                </OverflowMenu>
              )}

              <Button
                appearance="ghost"
                size="large"
                onPress={() => setShowSummary(!showSummary)}
                accessoryLeft={(props) => (
                  <Icon
                    {...props}
                    icon={showSummary ? faShoppingBag : faList}
                  />
                )}
              >
                <Text>{showSummary ? "Show Orders" : "Show Summary"}</Text>
              </Button>
            </Layout>
          </Layout>
        </CenteredContainer>
      </Card>
      {Platform.OS === "web" && (
        <Card
          style={{
            paddingHorizontal: 0,
          }}
        >
          <CenteredContainer>
            <Layout
              style={{
                padding: 0,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Layout style={{ flexGrow: 1 }}>
                <Select
                  options={locations.map(({ key, name }) => ({
                    label: name,
                    value: { key, name } as BaitLocation,
                  }))}
                  value={locationFilterSelections}
                  summarizeModelName="Locations"
                  onSelect={(e) => {
                    setLocationFilterSelections(e);
                  }}
                  multi
                />
              </Layout>
              <Layout style={{ flexGrow: 1 }}>
                <Select
                  options={users.map(({ firstName, lastName, email }) => ({
                    label: `${firstName} ${lastName}`,
                    value: email,
                  }))}
                  summarizeModelName="Users"
                  value={userFilterSelections}
                  onSelect={(e) => {
                    setUserFilterSelections(e);
                  }}
                  multi
                />
              </Layout>
              {showSummary && (
                <>
                  <OverflowMenu
                    style={{ display: "flex", flexGrow: 1 }}
                    anchor={() => (
                      <Button
                        appearance="ghost"
                        size="large"
                        accessoryLeft={(props) => (
                          <Icon {...props} icon={faFilter} />
                        )}
                        onPress={() =>
                          setShowSummaryGroupSelector(!showSummaryGroupSelector)
                        }
                      >
                        {`By ${summaryGroupMode}`}
                      </Button>
                    )}
                    visible={showSummaryGroupSelector}
                    selectedIndex={
                      new IndexPath(
                        ["Fisher", "Species", "Location", "User"].indexOf(
                          summaryGroupMode
                        )
                      )
                    }
                    onSelect={(path: IndexPath) => {
                      setSummaryGroupMode(
                        [
                          "Fisher",
                          "Species",
                          "Species Price",
                          "Location",
                          "User",
                        ][path.row] as
                          | "Fisher"
                          | "Species"
                          | "Species Price"
                          | "Location"
                          | "User"
                      );
                      setShowSummaryGroupSelector(false);
                    }}
                    onBackdropPress={() => setShowSummaryGroupSelector(false)}
                  >
                    <MenuItem title="By Fisher" />
                    <MenuItem title="By Species" />
                    <MenuItem title="By Species Price" />
                    <MenuItem title="By Location" />
                    <MenuItem title="By User" />
                  </OverflowMenu>
                  <Button
                    style={{}}
                    appearance="ghost"
                    size="large"
                    onPress={() => handleSummaryExportToCsv()}
                    accessoryLeft={(props) => (
                      <Icon {...props} icon={faDownload} />
                    )}
                  >
                    <Text>Export</Text>
                  </Button>
                </>
              )}
              {!showSummary && (
                <Button
                  style={{}}
                  appearance="ghost"
                  size="large"
                  onPress={() => handleOrdersExportToCsv()}
                  accessoryLeft={(props) => (
                    <Icon {...props} icon={faDownload} />
                  )}
                >
                  <Text>Export</Text>
                </Button>
              )}
            </Layout>
          </CenteredContainer>
        </Card>
      )}
      <Layout
        level="4"
        style={{ flex: 1, justifyContent: "flex-start", paddingHorizontal: 0 }}
      >
        <CenteredContainer>
          <Layout level="4" style={{ flex: 1 }}>
            {!isLoadingOrders &&
              !isFetchingOrders &&
              filteredOrders.length === 0 && (
                <Layout
                  level="4"
                  style={{
                    display: "flex",
                    flex: 100,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Pressable
                    onPress={() => refetchOrders()}
                    style={{
                      display: "flex",
                      flex: 100,
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Icon
                      style={{ width: 48, height: 48, marginBottom: 12 }}
                      color={theme["background-alternative-color-2"]}
                      icon={faSync}
                    />
                    <Text>No Orders To Display</Text>
                  </Pressable>
                </Layout>
              )}
            {showSummary && (
              <Layout style={{ flex: 1 }}>
                <ScrollView>
                  {summaryGroupMode === "Fisher" && (
                    <>
                      <Row
                        data={[
                          "Fisher Id",
                          "Fisher Name",
                          "Item",
                          "ID",
                          "Count",
                        ]}
                        style={styles.head}
                        textStyle={styles.text}
                      />
                      <Rows
                        style={styles.row}
                        data={fisherSummaryData}
                        textStyle={styles.text}
                      />
                    </>
                  )}
                  {summaryGroupMode === "Species" && (
                    <>
                      <Row
                        data={["Species", "Item", "ID", "Count"]}
                        style={styles.head}
                        textStyle={styles.text}
                      />
                      <Rows
                        style={styles.row}
                        data={speciesSummaryData}
                        textStyle={styles.text}
                      />
                    </>
                  )}
                  {summaryGroupMode === "Species Price" &&
                    speciesPriceSummaryData.map(({ rows, totals }) => (
                      <>
                        <Layout>
                          <Row
                            data={[
                              "Created",
                              "Location",
                              "Fisher CP ID",
                              "Company Name",
                              "Item name",
                              "Number sold",
                              "Item CP ID",
                            ]}
                            style={styles.head}
                            textStyle={styles.text}
                          />
                          <Rows
                            data={rows}
                            textStyle={styles.text}
                            style={styles.row}
                          />
                        </Layout>
                        <Layout>
                          <Row
                            data={[
                              "",
                              "",
                              "",
                              "",
                              "",
                              "",
                              "",
                              `Per Unit: ${totals.perUnit}`,
                              `Total: ${totals.total}`,
                            ]}
                            style={styles.summaryRow}
                            textStyle={styles.text}
                          />
                        </Layout>
                      </>
                    ))}
                  {summaryGroupMode === "Location" && (
                    <>
                      <Row
                        data={["Location", "Item", "ID", "Count"]}
                        style={styles.head}
                        textStyle={styles.text}
                      />
                      <Rows
                        style={styles.row}
                        data={locationSummaryData}
                        textStyle={styles.text}
                      />
                    </>
                  )}
                  {summaryGroupMode === "User" && (
                    <>
                      <Row
                        data={["User", "Item", "ID", "Count"]}
                        style={styles.head}
                        textStyle={styles.text}
                      />
                      <Rows
                        style={styles.row}
                        data={userSummaryData}
                        textStyle={styles.text}
                      />
                    </>
                  )}
                </ScrollView>
              </Layout>
            )}
            {!showSummary && (
              <FlatList
                onRefresh={() => refetchOrders()}
                refreshing={isLoadingOrders || isFetchingOrders}
                data={filteredOrders}
                keyExtractor={(item) => `${item.id}-${item.updatedAt}`}
                renderItem={({ item: order }) => (
                  <BaitOrderCard
                    navigation={navigation}
                    order={order}
                    style={{ margin: 24 }}
                  />
                )}
              />
            )}
            {Platform.OS === "web" && !showSummary && (
              <Layout
                style={{
                  padding: 24,
                  marginHorizontal: 24,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text category="s1">{filteredOrders.length} Orders</Text>
                <Layout>
                  <Text category="c2">
                    {
                      filteredOrders.filter(
                        (order) => !order.syncedToCounterPoint
                      ).length
                    }{" "}
                    NEW
                  </Text>
                  <Text category="s2">
                    {
                      filteredOrders.filter(
                        (order) => !!order.syncedToCounterPoint
                      ).length
                    }{" "}
                    ALREADY SENT
                  </Text>
                </Layout>

                {!isSyncingToCounterPoint && (
                  <Button
                    onPress={() => handleSendToCounterPoint()}
                    disabled={
                      filteredOrders.filter(
                        (order) => !order.syncedToCounterPoint
                      ).length === 0
                    }
                  >
                    <Text>
                      Send{" "}
                      {
                        filteredOrders.filter(
                          (order) => !order.syncedToCounterPoint
                        ).length
                      }{" "}
                      To CounterPoint
                    </Text>
                  </Button>
                )}
                {isSyncingToCounterPoint && (
                  <Button disabled>
                    <Text>
                      <Spinner /> Syncing Orders
                    </Text>
                  </Button>
                )}
              </Layout>
            )}
            <Button size="giant" onPress={() => handleNewOrder()}>
              New Order
            </Button>
          </Layout>
        </CenteredContainer>
      </Layout>
      <Modal {...fisherSelectionModalProps}>
        <FisherSelectionModal
          onSelect={handleFisherSelectionChanged}
          selected={fisherSelection.map(({ counterPointId }) => counterPointId)}
          multi
        />
      </Modal>
    </Layout>
  );
};
