import { useState } from "react";
import Toast from "react-native-root-toast";
import { v4 as uuid } from "uuid";

import { useApi, Fisher } from "../api";

export const useFisher = () => {
  const api = useApi();
  const toastOptions = {
    duration: Toast.durations.LONG,
    position: -150,
  };

  const [fisher, setFisherState] = useState<Fisher>({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    enabled: true,
    processedBackOffice: 0,
    dynamoId: uuid(),
  });

  const setFisher = (stateFn: (state: Fisher) => Fisher) => {
    setFisherState(stateFn(fisher));
  };

  const { firstName, lastName, companyName, email } = fisher;

  const setFirstName = (firstName: string) => {
    setFisher((fisher) => ({ ...fisher, firstName }));
  };

  const setLastName = (lastName: string) => {
    setFisher((fisher) => ({ ...fisher, lastName }));
  };

  const setCompanyName = (companyName: string) => {
    setFisher((fisher) => ({ ...fisher, companyName }));
  };

  const setEmail = (email: string) => {
    setFisher((fisher) => ({ ...fisher, email }));
  };

  const { mutateAsync: createFisherMutation } = api.Fisher.create();

  const resetFisher = () => {
    setFisher(() => ({
      firstName: "",
      lastName: "",
      companyName: "",
      email: "",
      enabled: true,
      processedBackOffice: 0,
      dynamoId: uuid(),
    }));
  };

  const submitFisher = async () => {
    let message = "";
    let result: Fisher | null = null;

    if (!firstName) {
      message += "First Name is required";
    }

    if (!lastName) {
      if (message) message += "\n";
      message += "Last Name is required";
    }

    if (!firstName || !lastName) {
      Toast.show(message, toastOptions);
    }

    if (firstName && lastName) {
      // result = await createFisherMutation(fisher);
      result = fisher;
      Toast.show("Fisher added successfully", toastOptions);
    }
    return result;
  };

  return {
    //Methods
    setFirstName,
    setLastName,
    setCompanyName,
    setEmail,
    submitFisher,
    resetFisher,
    //States
    firstName,
    lastName,
    companyName,
    email,
  };
};
