import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { LandingScreen } from "./LandingScreen";
import { LandingsScreen } from "./LandingsScreen";
import { SignatureScreen } from "./SignatureScreen";
import type { LandingsStackParamsList } from "./types";
import { useApi } from "../../api";
import { StationSelectionScreen } from "../StationSelectionScreen";

const LandingsStackNavigator =
  createNativeStackNavigator<LandingsStackParamsList>();

export function LandingsNavigator() {
  const api = useApi();

  api.Fisher.search();
  api.Species.search();
  api.RSBaitOrder.search();

  return (
    <LandingsStackNavigator.Navigator initialRouteName="Landings">
      <LandingsStackNavigator.Screen
        component={LandingScreen}
        name="Landing"
        options={{ headerShown: false }}
      />
      <LandingsStackNavigator.Screen
        component={LandingsScreen}
        name="Landings"
        options={{
          headerShown: false,
        }}
      />
      <LandingsStackNavigator.Screen
        component={StationSelectionScreen}
        name="StationSelection"
        options={{ headerShown: false }}
      />
      <LandingsStackNavigator.Screen
        component={SignatureScreen}
        name="Signature"
        options={{ headerShown: false }}
      />
    </LandingsStackNavigator.Navigator>
  );
}
