import { Layout, Button } from "@ui-kitten/components";
import { ScrollView } from "react-native";

import { TopNavigation } from "../components";
import { useBaitLocation } from "../context/baitLocationProvider";
import { RootScreenProps } from "../types";

export function BaitLocationSelectionScreen({ navigation }: RootScreenProps) {
  const { locations, location, setLocation } = useBaitLocation();

  return (
    <>
      <TopNavigation
        navigation={navigation}
        title="Change Location"
        enableBackAction
      />
      <Layout style={{ paddingHorizontal: 72, flex: 1 }}>
        <ScrollView>
          {locations.map(({ key, name }) => (
            <Button
              key={key}
              disabled={name === location.name}
              size="giant"
              style={{ marginVertical: 18 }}
              onPress={() => {
                setLocation({ name, key });
                navigation.navigate("Orders", {
                  screen: "Orders",
                  params: undefined,
                });
              }}
            >
              {name}
            </Button>
          ))}
        </ScrollView>
      </Layout>
    </>
  );
}
