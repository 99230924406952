import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { StyleService, useStyleSheet, useTheme } from "@ui-kitten/components";
import { StyleSheet } from "react-native";

import { Icon } from "./Icon";
import { View, Text } from "./Themed";

const themedStyles = StyleService.create({
  chip: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 6,
    borderRadius: 12,
    backgroundColor: "color-primary-500",
  },
  chipText: {
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 6,
    color: "color-basic-300",
  },
});

export type ChipProps = {
  icon: IconProp;
  label: string;
};

export const Chip = ({ icon, label }: ChipProps) => {
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  return (
    <View style={styles.chip}>
      <Icon icon={icon} size={14} color={theme["color-primary-100"]} />
      <Text style={styles.chipText}>{label}</Text>
    </View>
  );
};
