import { faHashtag } from "@fortawesome/pro-regular-svg-icons";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FlashList } from "@shopify/flash-list";
import { Text, Button, Layout } from "@ui-kitten/components";
import moment from "moment";
import { useMemo } from "react";
import { View, StyleSheet, FlatList } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import { TunaStackParamsList } from "./types";
import { StagedDelivery, useApi } from "../../api";
import { Icon, TopNavigation } from "../../components";

const styles = StyleSheet.create({
  card: {
    marginHorizontal: 12,
    marginTop: 24,
    padding: 12,
    display: "flex",
    flexDirection: "column",
    borderRadius: 12,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    paddingVertical: 6,
  },
});

const getDeliveryTagNumber = (delivery: StagedDelivery) => {
  const [item] = delivery.items;
  return item?.metadata?.tagNumber;
};



const getDeliveryQuantity = (delivery: StagedDelivery) => {
  const [item] = delivery.items;
  return `${item?.count ?? 0} lb.`;
};

const getDeliverySlushTank = (
  containers: { id: string; name: string }[],
  delivery: StagedDelivery
) => {
  const [item] = delivery.items;
  if (!item) {
    return "";
  }
  const { metadata } = item;
  const { containerId } = metadata;
  const container = containers.find(({ id }) => id === containerId);
  if (container) {
    return container?.name;
  }
  return "";
};

export const TunaScreen = ({
  navigation,
}: NativeStackScreenProps<TunaStackParamsList>) => {
  const { StagedDelivery, TracktileFlow } = useApi();
  const {
    data: deliveries = [],
    isFetching,
    refetch: refetchStagedDeliveries,
  } = StagedDelivery.all();
  const { data: { nodes = [] } = {} } = TracktileFlow.call({});

  const visibleDeliveries = useMemo(
    () =>
      deliveries.sort(({ date: a }, { date: b }) => {
        const [da, db] = [a, b].map((d) => moment(d));
        if (da.isBefore(db)) {
          return 1;
        }
        if (da.isAfter(db)) {
          return -1;
        }
        return 0;
      }),
    [deliveries]
  );

  const containers = useMemo(
    () =>
      nodes
        .filter((node) => node.behaviour.type === "container")
        .sort(({ name: a }, { name: b }) => a.localeCompare(b)),
    [nodes]
  );

  return (
    <Layout style={{ flex: 1 }}>
      <TopNavigation navigation={navigation} title="Tuna" showMenuAction />
      <FlatList
        onRefresh={() => refetchStagedDeliveries()}
        refreshing={isFetching}
        data={visibleDeliveries}
        renderItem={({ item: delivery }) => {
          return (
            <Layout level="2" style={styles.card}>
              <View style={[styles.row, { justifyContent: "space-between" }]}>
                <View style={[styles.row, { alignItems: "center" }]}>
                  <Icon icon={faHashtag} size={12} style={{ marginRight: 6 }} />
                  <Text>{getDeliveryTagNumber(delivery)}</Text>
                </View>
                <View style={[styles.row]}>
                  <Text>{getDeliveryQuantity(delivery)}</Text>
                </View>
              </View>
              <View style={[styles.row, { justifyContent: "space-between" }]}>
                <Text>{getDeliverySlushTank(containers, delivery)}</Text>
                <Text>{moment(delivery.date).fromNow()}</Text>
              </View>
            </Layout>
          );
        }}
      />
      <SafeAreaView edges={["bottom"]}>
        <Button onPress={() => navigation.navigate("NewTuna")}>New Tuna</Button>
      </SafeAreaView>
    </Layout>
  );
};
