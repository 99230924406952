import {
  faMinusCircle,
  faPlusCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { Layout, Button, Input, Text } from "@ui-kitten/components";
import { FC, useState } from "react";
import { Platform, View } from "react-native";

import { Icon } from "./Icon";

interface CountInputProps {
  value: number;
  onChange: (n: number) => void;
  unit?: string;
  min?: number;
  max?: number;
  step?: number;
}

export const CountInput: FC<CountInputProps> = ({
  value,
  onChange,
  unit,
  min = 0,
  max = 999999999,
  step = 1,
}: CountInputProps) => {
  const [localValue, setLocalValue] = useState<string>(`${value}`);

  const handleChanged = (value: string | number) => {
    if (`${value}`.endsWith(".")) {
      setLocalValue(`${value}`);
      return;
    }
    const n = parseFloat(parseFloat(`${value}`).toFixed(2));
    setLocalValue(`${n}`);
    if (!Number.isNaN(n)) {
      onChange(n);
    }
  };

  return (
    <Layout>
      <Layout style={{ flexDirection: "row", alignItems: "center" }}>
        <Button
          disabled={value <= min}
          appearance="outline"
          size={Platform.OS === "web" ? "medium" : "tiny"}
          accessoryLeft={(props) => <Icon {...props} icon={faMinusCircle} />}
          onPress={() => handleChanged(Math.max(value - step, min))}
        />
        <View style={{ flexDirection: "column", flexGrow: 1 }}>
          <Input
            keyboardType="numeric"
            textAlign="center"
            style={{ marginHorizontal: 5 }}
            textStyle={{ paddingRight: 40 }}
            size={Platform.OS === "web" ? "giant" : "small"}
            value={localValue}
            onBlur={() => {
              if (parseFloat(localValue) < min) {
                handleChanged(min);
              }
              if (parseFloat(localValue) > max) {
                handleChanged(max);
              }
            }}
            onChangeText={(value: string) => {
              handleChanged(value);
            }}
          />
          {!!unit && (
            <Text
              style={{
                position: "absolute",
                top: 8,
                right: 12,
              }}
            >
              {unit}
            </Text>
          )}
        </View>
        <Button
          disabled={value >= max}
          appearance="outline"
          size={Platform.OS === "web" ? "medium" : "tiny"}
          accessoryLeft={(props) => (
            <Icon style={props?.style} icon={faPlusCircle} />
          )}
          onPress={() => handleChanged(Math.min(value + step, max))}
        />
      </Layout>
    </Layout>
  );
};
