import { PropsWithChildren, createContext, useContext } from "react";

import { useStorage } from "../lib/storage";

const BAIT_LOCATION_MAP = {
  NAC: "North Atlantic Cold Storage",
  ROYL: "ROYAL STAR FOODS LTD",
  RUN: "RUN",
  SKP: "Skinners Pond Bait",
};

export interface BaitLocation {
  name: string;
  key: string;
}

interface LocationContextParams {
  locations: BaitLocation[];
  location: BaitLocation;
  setLocation: (location: BaitLocation) => void;
}

const BAIT_LOCATIONS = Object.entries(BAIT_LOCATION_MAP).map(
  ([key, name]) => ({ key, name }) as BaitLocation
);

const DEFAULT_LOCATION = BAIT_LOCATIONS[0];

const BaitLocationContext = createContext<LocationContextParams>({
  setLocation: (location: BaitLocation) => {},
  location: DEFAULT_LOCATION,
  locations: BAIT_LOCATIONS,
});

export const BaitLocationProvider = ({
  children,
}: PropsWithChildren<object>) => {
  const [storedLocation, setStoredLocation] = useStorage<BaitLocation>(
    "@BaitLocation",
    DEFAULT_LOCATION
  );

  return (
    <BaitLocationContext.Provider
      value={{
        locations: BAIT_LOCATIONS,
        location: storedLocation as BaitLocation,
        setLocation: setStoredLocation,
      }}
    >
      {children}
    </BaitLocationContext.Provider>
  );
};

export const useBaitLocation = () => useContext(BaitLocationContext);
