import { faClose, faUnlock } from "@fortawesome/pro-regular-svg-icons";
import {
  Layout,
  Text,
  Button,
  Spinner,
  Card,
  StyleService,
  useStyleSheet,
} from "@ui-kitten/components";
import {
  useState,
  useContext,
  createContext,
  ReactNode,
  useEffect,
} from "react";
import { useFormContext } from "react-hook-form";
import {
  Dimensions,
  Platform,
  SafeAreaView,
  ScrollView,
  View,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import { User } from "../api";
import * as Auth from "../api/auth";
import { ControlledInput } from "../components/ControlledInput";
import { Form } from "../components/Form";
import { Icon } from "../components/Icon";
import { Logo } from "../components/Logo";
import { useStorage } from "../lib/storage";

export type TenantConfiguration = {
  defaultScreen: string;
  baitOrders: {
    enabled: boolean;
  };
  deliveries: {
    enabled: boolean;
  };
  stations: {
    enabled: boolean;
  };
  admin: {
    appUsers: boolean;
  };
  account?: {
    vesselManagement: boolean;
    tunaFunctionality: boolean;
  };
};

const DEFAULT_TENANT_CONFIG: TenantConfiguration = {
  defaultScreen: "Landings",
  baitOrders: {
    enabled: false,
  },
  deliveries: {
    enabled: false,
  },
  stations: {
    enabled: false,
  },
  admin: {
    appUsers: false,
  },
} as const;

export interface SeafareAPIJWT {
  tenant: TenantConfiguration;
  sbmDomain: string;
  sbmAccountId: number;
  email: string;
  sbmUserId?: number;
  appUserId?: string;
  firstName?: string;
  lastName?: string;
}

const chars =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=" as const;

const atob = (input = "") => {
  const str = input.replace(/=+$/, "");
  let output = "";

  if (str.length % 4 === 1) {
    throw new Error(
      "'atob' failed: The string to be decoded is not correctly encoded."
    );
  }
  for (
    let bc = 0, bs = 0, buffer, i = 0;
    (buffer = str.charAt(i++));
    ~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4)
      ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
      : 0
  ) {
    buffer = chars.indexOf(buffer);
  }

  return output;
};

function parseJwt(token: string): SeafareAPIJWT {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

interface AuthContextParams {
  token?: string;
  domain?: string;
  user?: User;
  login: (params: Auth.LoginParams) => void;
  logout: () => void;
  isLoggingIn: boolean;
  loginError?: string;
  storedEmail?: string;
  tenant?: TenantConfiguration;
}

const AuthContext = createContext<AuthContextParams>({
  login: () => {},
  logout: () => {},
  isLoggingIn: false,
});

interface AuthProviderProps {
  children: ReactNode;
}

const themedStyles = StyleService.create({
  errorCard: {
    marginBottom: 20,
    backgroundColor: "color-danger-600",
  },
  errorCardText: {
    color: "#fff",
  },
});

interface AuthFormErrors {
  email?: string;
}

interface LoginFormProps {
  onSubmit: (params: Auth.LoginParams) => void;
  isSubmitting: boolean;
  error?: string;
}

const LoginForm = ({ onSubmit, isSubmitting, error }: LoginFormProps) => {
  const styles = useStyleSheet(themedStyles);
  const {
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useFormContext<Auth.LoginParams>();

  const { email, password } = watch();
  const displayedError =
    Object.values(errors).find(() => true)?.message ?? error;

  return (
    <>
      {Platform.OS === "web" && (
        <>
          <ControlledInput name="domain" label="SBM Domain" />
          <ControlledInput name="username" label="SBM Username" />
        </>
      )}
      {Platform.OS !== "web" && (
        <ControlledInput
          name="email"
          label="Email Address"
          rules={{ required: "Email Address is required." }}
          status={errors.email ? "danger" : undefined}
          disabled={isSubmitting}
          autoComplete="email"
          autoCapitalize="none"
          autoCorrect={false}
          showFieldError={false}
          keyboardType="email-address"
          style={{ marginBottom: 20 }}
          accessoryRight={(props) => {
            if (!isSubmitting && (email?.length ?? -1) > 0) {
              return (
                <Button onPress={() => setValue("email", undefined)}>
                  <Icon {...props} icon={faClose} />
                </Button>
              );
            }
            return <></>;
          }}
        />
      )}
      <ControlledInput
        name="password"
        label="Password"
        rules={{ required: "Password is required." }}
        status={errors.email ? "danger" : undefined}
        showFieldError={false}
        disabled={isSubmitting}
        autoCorrect={false}
        autoComplete="password"
        keyboardType="default"
        autoCapitalize="none"
        textContentType="password"
        secureTextEntry
        style={{ marginBottom: 20 }}
        accessoryRight={(props) => {
          if (!isSubmitting && (password?.length ?? -1 > 0)) {
            return (
              <Button onPress={() => setValue("password", "")}>
                <Icon {...props} icon={faClose} />
              </Button>
            );
          }
          return <></>;
        }}
      />
      {displayedError && (
        <Card style={styles.errorCard}>
          <Text style={styles.errorCardText}>{displayedError}</Text>
        </Card>
      )}
      <Button
        disabled={isSubmitting}
        onPress={handleSubmit(onSubmit)}
        accessoryLeft={(props) => <Icon {...props} icon={faUnlock} />}
      >
        {!isSubmitting ? "Login" : "Logging in..."}
      </Button>
    </>
  );
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [storedUsername, setStoredUsername] = useStorage<string>("username");
  const [storedEmail, setStoredEmail] = useStorage<string>("email");
  const [storedDomain, setStoredDomain] = useStorage<string>("domain");
  const [storedToken, setStoredToken] = useStorage<string>("token");
  const [storedTenantConfiguration, setStoredTenantConfiguration] =
    useStorage<TenantConfiguration>("config", DEFAULT_TENANT_CONFIG);

  const [storedUser, setStoredUser] = useStorage<User>("user");
  const [loginError, setLoginError] = useState<string | undefined>();

  const {
    data: identity,
    refetch: refetchIdentity,
  } = Auth.Identity({
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
    enabled: !!storedToken,
  });

  useEffect(() => {
    setStoredUser(identity);
    if (identity?.email) {
      setStoredEmail(identity.email);
    }
  }, [identity]);

  const { mutate: loginMutation, isPending: isLoggingIn } = Auth.Login({
    retry: 0,
    onSuccess: ({ token }) => {
      setLoginError(undefined);
      const parsed = parseJwt(token);
      setStoredToken(token);
      setStoredTenantConfiguration(parsed.tenant);
      refetchIdentity();
    },
    onError(error: unknown) {
      if (error instanceof Error) {
        setLoginError(error.message);
      }
    },
  });

  const login = (params: Auth.LoginParams) => {
    if (
      params.domain &&
      !params.domain.endsWith(".seafaresystems.com") &&
      !params.domain.endsWith("localhost")
    ) {
      params.domain = `${params.domain}.seafaresystems.com`;
    }
    if (params.email) {
      setStoredEmail(params.email);
    }
    if (params.username) {
      setStoredUsername(params.username);
    }
    if (params.domain) {
      setStoredDomain(params.domain);
    }
    loginMutation(params);
  };

  const logout = () => {
    setStoredToken();
    setStoredUser();
    setStoredEmail();
    setStoredUsername();
    setStoredTenantConfiguration();
    setStoredTenantConfiguration();
  };

  if (!storedUser && identity) {
    return (
      <Layout
        style={{
          flexGrow: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner size="large" />
        <Text category="S1" style={{ marginVertical: 24 }}>
          Going Fishing..
        </Text>
      </Layout>
    );
  }

  if (!storedUser) {
    return (
      <Layout
        style={{
          flex: 1,
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <SafeAreaView>
          <ScrollView>
            <Layout
              style={{
                paddingHorizontal: 20,
                marginVertical: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Logo
                style={{
                  padding:
                    Dimensions.get("window").width > 400
                      ? 100
                      : Dimensions.get("window").width / 5,
                  marginTop: 20,
                  marginHorizontal: 20,
                  height: 10,
                }}
              />
              <Text category="h1" style={{ marginVertical: 18 }} />
              <Layout style={{ width: "100%", maxWidth: 320 }}>
                <Form
                  defaultValues={
                    {
                      domain: storedDomain ?? "",
                      username: storedUsername ?? "",
                      email: storedEmail ?? "",
                      password: "",
                    } as Auth.LoginParams
                  }
                >
                  <LoginForm
                    onSubmit={login}
                    isSubmitting={isLoggingIn}
                    error={loginError}
                  />
                </Form>
              </Layout>
            </Layout>
          </ScrollView>
        </SafeAreaView>
      </Layout>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        token: storedToken,
        user: storedUser,
        domain: storedDomain,
        login,
        logout,
        isLoggingIn,
        loginError,
        storedEmail,
        tenant: storedTenantConfiguration,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export const useUser = () => {
  const { user } = useAuth();
  if (!user) {
    throw new Error("Not authenticated");
  }
  return user;
};
