import { request } from "@tracktile/axiom";
import moment from "moment";
import { v4 as uuid } from "uuid";

import { StagedDelivery, Station, User } from "./models";
import { getConfig } from "../config";
import { queryClient } from "../queryClient";

const getBlob = async (fileUri: string) => {
  const resp = await fetch(fileUri);
  const imageBody = await resp.blob();
  return imageBody;
};

export type AddTunaCall = {
  token: string;
  fileUri: string;
  tagNumber: string;
  containerId: string;
  user: User;
  station: Station;
  fileKey: string;
  extension: string;
  stagedDelivery: StagedDelivery;
};

queryClient.setMutationDefaults(["createStagedDeliveryWithFileUpload"], {
  mutationFn: async ({
    token,
    fileUri,
    fileKey,
    extension,
    stagedDelivery,
  }: AddTunaCall) => {
    const config = getConfig();

    const [{ url, key }] = await request<unknown, { url: string; key: string }>(
      `${config.api}/storage?ext=${extension}&name=${fileKey}`,
      {
        method: "POST",
        body: {},
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    await fetch(url, {
      method: "PUT",
      body: await getBlob(fileUri),
    });

    await request<StagedDelivery>(`${config.api}/deliveries/staged`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        ...stagedDelivery,
        documents: [
          {
            key,
            name: "ICATT",
          },
        ],
      },
    });
  },
  onMutate: async ({
    stagedDelivery,
    containerId,
    tagNumber,
    user,
    station,
  }: AddTunaCall) => {
    const optimisticDelivery: StagedDelivery = {
      id: stagedDelivery.id,
      isNew: true,
      date: new Date().toISOString(),
      batchNumber: moment().format("MMMDDYY"),
      slipNumber: `${stagedDelivery.slipNumber}`,
      slipDate: moment().format("Y-MM-DD").toString(),
      items: stagedDelivery.items,
      fishers: [],
      deductions: [],
      vessel: undefined,
      user,
      locationKey: station?.name,
      buyingStation: station,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      signatureId: "",
      syncedToBackend: false,
      syncedToSBM: false,
      documents: [],
    };

    await queryClient.cancelQueries({ queryKey: ["stagedDelivery", {}] });
    queryClient.setQueryData(
      ["stagedDelivery", {}],
      (old: StagedDelivery[] = []) => [...old, optimisticDelivery]
    );
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ["stagedDelivery", {}] });
  },
});
