import { createApiModel, Type, Static } from "@tracktile/axiom";

export const Signature = createApiModel({
  name: "signatures",
  resource: "/signatures",
  schema: Type.Object({
    id: Type.String(),
    isSaved: Type.Optional(Type.Boolean()),
    deliveryId: Type.String(),
    signatureBase64: Type.String(),
    createdAt: Type.Number(),
    updatedAt: Type.Number(),
  }),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Signature = Static<typeof Signature.schema>;
