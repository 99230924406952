import { createApiModel, Type, Static } from "@tracktile/axiom";

export const Station = createApiModel({
  name: "stations",
  resource: "/stations",
  schema: Type.Object({
    id: Type.String(),
    name: Type.String(),
    statsDistrict: Type.String(),
    active: Type.Number(),
  }),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Station = Static<typeof Station.schema>;
