import { createApiModel, Type, Static } from "@tracktile/axiom";

export const Fisher = createApiModel({
  name: "fishers",
  resource: "/fishers",
  schema: Type.Object({
    id: Type.Optional(Type.String()),
    firstName: Type.String(),
    lastName: Type.String(),
    companyName: Type.Optional(Type.String()),
    email: Type.Optional(Type.String()),
    vesselName: Type.Optional(Type.String()),
    counterPointId: Type.Optional(Type.Number()),
    station: Type.Optional(Type.Number()),
    enabled: Type.Boolean(),
    dynamoId: Type.Optional(Type.String()),
    processedBackOffice: Type.Number(),
  }),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Fisher = Static<typeof Fisher.schema>;
