import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  createDrawerNavigator,
  DrawerContentComponentProps,
} from "@react-navigation/drawer";
import {
  NavigationContainer,
  NavigatorScreenParams,
} from "@react-navigation/native";
import {
  Layout,
  Text,
  Drawer,
  DrawerItem,
  IndexPath,
  Divider,
} from "@ui-kitten/components";
import { StatusBar } from "expo-status-bar";
import moment from "moment";
import { useEffect, ComponentType } from "react";
import { ViewProps } from "react-native";

import { Logo, Icon } from "./components";
import { getConfig } from "./config";
import { useAuth } from "./context/authProvider";
import { useSyncContext } from "./context/syncProvider";
import { LandingsStackParamsList } from "./screens/landings/types";
import { OrdersStackParamsList } from "./screens/royalstar/types";
import pkg from "../package.json";

export type NavigationStackParamList = {
  Orders: NavigatorScreenParams<OrdersStackParamsList>;
  Landings: NavigatorScreenParams<LandingsStackParamsList>;
  "Change Station": undefined;
  "Change Location": undefined;
  Admin: undefined;
  Logout: undefined;
};

export const RootStackNavigator =
  createDrawerNavigator<NavigationStackParamList>();

export function Logout() {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
  }, []);

  // TODO: confirmation modal on logout
  return <></>;
}

function DrawerHeader(props: ViewProps) {
  const { user } = useAuth();
  return (
    <>
      <StatusBar />
      <Layout
        style={{
          padding: 18,
          paddingTop: 60,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Logo style={{ width: 32, height: 32, marginRight: 18 }} />
        <Text category="s1">{`${user?.firstName ?? ""} ${
          user?.lastName ?? ""
        }`}</Text>
      </Layout>
      <Divider />
    </>
  );
}

function DrawerFooter(props: ViewProps) {
  const { lastSyncedAt } = useSyncContext();
  return (
    <Layout
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 18,
      }}
    >
      <Text category="s1">{getConfig().runMode}</Text>
      <Text>{`v${pkg.version}`}</Text>
      {lastSyncedAt && (
        <Text>{`Last Synced ${moment(lastSyncedAt).fromNow()}`}</Text>
      )}
    </Layout>
  );
}

export type TabDescriptor = {
  title: string;
  name: string;
  icon: IconProp;
  Component: ComponentType<any>;
};

export function ApplicationNavigator({ tabs }: { tabs: TabDescriptor[] }) {
  const { tenant } = useAuth();
  return (
    <NavigationContainer>
      <RootStackNavigator.Navigator
        initialRouteName={
          (tenant?.defaultScreen as keyof NavigationStackParamList) ?? undefined
        }
        useLegacyImplementation={false}
        drawerContent={({ state, navigation }: DrawerContentComponentProps) => (
          <Drawer
            header={(props) => <DrawerHeader {...props} />}
            footer={(props) => <DrawerFooter {...props} />}
            selectedIndex={new IndexPath(state.index)}
            onSelect={(index) => {
              navigation.navigate(state.routeNames[index.row]);
            }}
          >
            {tabs.map(({ title, icon }) => (
              <DrawerItem
                key={title}
                title={title}
                accessoryLeft={(props) => <Icon {...props} icon={icon} />}
              />
            ))}
          </Drawer>
        )}
      >
        {tabs.map(({ title, name, Component }) => (
          <RootStackNavigator.Screen
            key={title}
            name={name as keyof NavigationStackParamList}
            component={Component}
            options={{ headerShown: false }}
          />
        ))}
      </RootStackNavigator.Navigator>
    </NavigationContainer>
  );
}
