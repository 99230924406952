import { createApiModel, Type, Static } from "@tracktile/axiom";

export const Deduction = createApiModel({
  name: "deductions",
  resource: "/deductions",
  schema: Type.Object({
    itemId: Type.Optional(Type.Number()),
    itemName: Type.Optional(Type.String()),
    itemDescription: Type.Optional(Type.String()),
  }),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Deduction = Static<typeof Deduction.schema>;
