import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { NewTunaScreen } from "./NewTunaScreen";
import { TunaScreen } from "./TunaScreen";
import { TunaStackParamsList } from "./types";

const TunaStackNavigator = createNativeStackNavigator<TunaStackParamsList>();

export function TunaNavigator() {
  return (
    <TunaStackNavigator.Navigator initialRouteName="Tuna">
      <TunaStackNavigator.Screen
        component={TunaScreen}
        name="Tuna"
        options={{ headerShown: false }}
      />
      <TunaStackNavigator.Screen
        component={NewTunaScreen}
        name="NewTuna"
        options={{ headerShown: false }}
      />
    </TunaStackNavigator.Navigator>
  );
}
