import { FC, PropsWithChildren } from "react";
import { View, Platform, useWindowDimensions } from "react-native";

interface CenteredContainerProps {
  flexDirection?: "row" | "column";
}

export const CenteredContainer: FC<
  PropsWithChildren<CenteredContainerProps>
> = ({ children, flexDirection = "column" }) => {
  const { width } = useWindowDimensions();

  if (Platform.OS !== "web" || width < 768) {
    return (
      <View style={{ flex: 1, flexGrow: 1, flexDirection }}>{children}</View>
    );
  }
  return (
    <View style={{ flex: 1, flexDirection: "row" }}>
      <View style={{ flex: 1 }} />
      <View style={{ flex: 1, flexGrow: 10, flexDirection, maxWidth: 1620 }}>
        {children}
      </View>
      <View style={{ flex: 1 }} />
    </View>
  );
};
