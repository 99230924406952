import React, {
  MouseEventHandler,
  WheelEventHandler,
  useRef,
  useState,
} from "react";

import { ZoomableImageProps } from "./ZoomableImageShared";

const zoomScale = 4;
const transitionTime = 0.1;

export function ZoomableImage(props: ZoomableImageProps) {
  const { height, img, width } = props;
  const imageRef = useRef<HTMLDivElement>(null);
  const [zoom, setZoom] = useState(false);
  const [mouseX, setMouseX] = useState<number>(0);
  const [mouseY, setMouseY] = useState<number>(0);

  const outerDivStyle = {
    height: `${height}px`,
    width: `${width}px`,
    overflow: "hidden",
  };

  const innerDivStyle = {
    height: `${height}px`,
    width: `${width}px`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "auto 100%",
    transition: `transform ${transitionTime}s ease-out`,
    backgroundImage: `url('${img}')`,
  };

  const handleMouseOver = () => {
    setZoom(true);
  };

  const handleMouseOut = () => {
    setZoom(false);
  };

  const handleMouseMovement: MouseEventHandler<HTMLDivElement> = (e) => {
    const { left: offsetLeft, top: offsetTop } =
      imageRef?.current?.getBoundingClientRect() || {};

    const x = ((e.pageX - (offsetLeft ?? 0)) / width) * 100;
    const y = ((e.pageY - (offsetTop ?? 0)) / height) * 100;

    setMouseX(x);
    setMouseY(y);
  };

  const transform = {
    transformOrigin: `${mouseX}% ${mouseY}%`,
  };

  return (
    <div
      style={outerDivStyle}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onMouseMove={handleMouseMovement}
      ref={imageRef}
    >
      <div
        style={{
          ...transform,
          ...innerDivStyle,
          transform: zoom ? `scale(${zoomScale})` : "scale(1.0)",
          willChange: "transform",
        }}
      />
    </div>
  );
}
