import { NavigationProp, useNavigation } from "@react-navigation/native";
import { request } from "@tracktile/axiom";
import moment from "moment";
import { useState } from "react";
import Toast from "react-native-root-toast";
import { v4 as uuid } from "uuid";

import { RootStackParamList } from "../../types";
import { useApi, Signature, StagedDelivery } from "../api";
import { getConfig } from "../config";
import { useStorage } from "../lib/storage";

export const useSignature = (
  signatureId?: string,
  landing?: StagedDelivery
) => {
  const api = useApi();
  const [token] = useStorage<string>("token");
  const apiUrl = getConfig().api;
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const toastOptions = {
    duration: Toast.durations.LONG,
    position: -150,
  };

  const [signature, setSignatureState] = useState<Signature>({
    id: uuid(),
    isSaved: !!signatureId,
    deliveryId: landing ? landing.id : "",
    signatureBase64: "",
    createdAt: Date.now(),
    updatedAt: Date.now(),
  });

  const setSignature = (stateFn: (state: Signature) => Signature) => {
    setSignatureState(stateFn(signature));
  };

  const { deliveryId, signatureBase64, createdAt, isSaved } = signature;

  const setSignatureBase64 = (signatureBase64: string) => {
    setSignature((signature) => ({ ...signature, signatureBase64 }));
  };

  const setDeliveryId = (deliveryId: string) => {
    setSignature((signature) => ({ ...signature, deliveryId }));
  };

  // Updating Landing/Delivery with signatureId upon creation.
  const { mutate: updateLandingMutation } = api.StagedDelivery.update();

  const { mutate: updateSignatureMutation } = api.Signature.update();

  const { mutate: createSignatureMutation } = api.Signature.create({
    onSuccess: (data: Signature) => {
      if (landing) {
        //Update Delivery Slip with the details.

        updateLandingMutation(landing);
        setDeliveryId(landing.id);
        setSignature((signature) => ({ ...signature, isSaved: true }));
      }
    },
  });

  const { mutate: deleteSignatureMutation } = api.Signature.remove();

  const resetSignature = () => {
    setSignature(() => ({
      id: uuid(),
      isNew: true,
      deliveryId: landing ? landing.id : "",
      signatureBase64: "",
      createdAt: Date.now(),
      updatedAt: Date.now(),
    }));
  };

  const submitSignature = async () => {
    if (signature.isSaved) {
      updateSignatureMutation(signature);
      //   resetSignature();
    } else {
      if (landing) {
        landing.signatureId = signature.id;
      }
      createSignatureMutation(signature);
      Toast.show("Landing slip signed successfully", toastOptions);
    }
    api.StagedDelivery.invalidateAll();
    navigation.navigate("Landings");
  };

  const retrieveSignature = async (signatureId: string) => {
    try {
      const [signature] = await request<Signature>(
        `${apiUrl}/signatures/${signatureId}`,
        {
          method: "GET",
          token,
        }
      );
      setSignature(() => ({ isSaved: true, ...signature }));
    } catch (err) {
      console.error(err);
    }
  };

  const deleteSignature = async () => {
    await deleteSignatureMutation(signature);
    resetSignature();
  };

  return {
    //Methods
    resetSignature,
    submitSignature,
    deleteSignature,
    setSignatureBase64,
    setDeliveryId,
    //States
    deliveryId,
    signatureBase64,
    createdAt,
    isSaved,
    retrieveSignature,
  };
};
