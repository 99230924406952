import "setimmediate";
import "react-native-get-random-values";
import * as eva from "@eva-design/eva";
import {
  faClose,
  faFish,
  faGear,
  faInbox,
  faMapPin,
} from "@fortawesome/pro-regular-svg-icons";
import { ApplicationProvider, Layout } from "@ui-kitten/components";
import { registerRootComponent } from "expo";
import { useMemo } from "react";
import { Platform, LogBox } from "react-native";
import ErrorBoundary from "react-native-error-boundary";
import { RootSiblingParent } from "react-native-root-siblings";
import { SafeAreaProvider } from "react-native-safe-area-context";

import { useApi } from "./api";
import { AppLoaderProvider } from "./context/appLoaderProvider";
import { AuthProvider, useAuth } from "./context/authProvider";
import { BaitLocationProvider } from "./context/baitLocationProvider";
import { NetworkContextProvider } from "./context/networkProvider";
import { QueryClientProvider } from "./context/queryClientProvider";
import { StationsProvider } from "./context/stationsProvider";
import { SyncContextProvider } from "./context/syncProvider";
import { TenantLabelProvider } from "./context/tenantLabelsProvider";
import useCachedResources from "./hooks/useCachedResources";
import useColorScheme from "./hooks/useColorScheme";
import { StoragePreloader } from "./lib/storage";
import { Logout, ApplicationNavigator, TabDescriptor } from "./nav";
import {
  AdminScreen,
  BaitLocationSelectionScreen,
  BaitOrderNavigator,
  LandingsNavigator,
  StationSelectionScreen,
} from "./screens";
import { TunaNavigator } from "./screens/tuna/TunaNavigator";
import theme from "../theme.json";

// enableScreens();
LogBox.ignoreAllLogs();

const AuthenticatedApp = () => {
  const { tenant } = useAuth();
  const tabs: TabDescriptor[] = useMemo(
    () =>
      [
        {
          title: Platform.OS === "web" ? "Orders" : "Sell Bait",
          name: "Orders",
          icon: faInbox,
          Component: BaitOrderNavigator,
          enabled: !!tenant?.baitOrders?.enabled,
        },
        {
          title: "Landings",
          name: "Landings",
          icon: faInbox,
          Component: LandingsNavigator,
          enabled:
            !!tenant?.deliveries?.enabled &&
            (Platform.OS === "web" || !tenant.account?.tunaFunctionality),
        },
        {
          title: "Tuna",
          name: "Receive Tuna",
          icon: faFish,
          Component: TunaNavigator,
          enabled:
            !!tenant?.deliveries?.enabled &&
            Platform.OS !== "web" &&
            !!tenant.account?.tunaFunctionality,
        },
        {
          title: "Change Location",
          name: "Change Location",
          icon: faMapPin,
          Component: BaitLocationSelectionScreen,
          enabled: Platform.OS !== "web" && tenant?.baitOrders.enabled,
        },
        {
          title: "Change Station",
          name: "Change Station",
          icon: faMapPin,
          Component: StationSelectionScreen,
          enabled: Platform.OS !== "web" && tenant?.stations?.enabled,
        },
        {
          title: "Admin",
          name: "Admin",
          icon: faGear,
          Component: AdminScreen,
          enabled: Platform.OS === "web",
        },
        {
          title: "Logout",
          name: "Logout",
          icon: faClose,
          Component: Logout,
          enabled: true,
        },
      ].filter(({ enabled }) => !!enabled),
    [tenant]
  );

  const api = useApi();

  // api.TracktileFlow.call(
  //   {},
  //   {
  //     retry: true,
  //   }
  // );

  return (
    <Layout style={{ flex: 1 }}>
      <ApplicationNavigator tabs={tabs} />
    </Layout>
  );
};

function App() {
  const colorScheme = useColorScheme();
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <ErrorBoundary>
          <RootSiblingParent>
            <ApplicationProvider
              {...eva}
              theme={{ ...eva[colorScheme], ...theme }}
            >
              <NetworkContextProvider>
                <StoragePreloader>
                  <QueryClientProvider>
                    <AppLoaderProvider>
                      <AuthProvider>
                        <SyncContextProvider>
                          <BaitLocationProvider>
                            <StationsProvider>
                              <TenantLabelProvider>
                                <AuthenticatedApp />
                              </TenantLabelProvider>
                            </StationsProvider>
                          </BaitLocationProvider>
                        </SyncContextProvider>
                      </AuthProvider>
                    </AppLoaderProvider>
                  </QueryClientProvider>
                </StoragePreloader>
              </NetworkContextProvider>
            </ApplicationProvider>
          </RootSiblingParent>
        </ErrorBoundary>
      </SafeAreaProvider>
    );
  }
}

registerRootComponent(App);

export default App;
