import { createApiModel, Type, Static } from "@tracktile/axiom";

import { User } from "./users";

export const RSBaitQuantity = Type.Object({
  value: Type.Number(),
  unit: Type.String(),
  price: Type.Number(),
  counterPointId: Type.String(),
  enabled: Type.Boolean(),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RSBaitQuantity = Static<typeof RSBaitQuantity>;

export const RSBaitSpecies = createApiModel({
  name: "baitSpecies",
  resource: "/royalstar/bait/species",
  schema: Type.Object({
    id: Type.String(),
    name: Type.String(),
    enabled: Type.Boolean(),
    quantities: Type.Array(RSBaitQuantity),
  }),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RSBaitSpecies = Static<typeof RSBaitSpecies.schema>;

export const RSBaitOrderItem = Type.Object({
  id: Type.String(),
  position: Type.Number(),
  species: Type.Pick(RSBaitSpecies.schema, ["id", "name"]),
  quantity: RSBaitQuantity,
  count: Type.Number(),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RSBaitOrderItem = Static<typeof RSBaitOrderItem>;

export const RSBaitFisher = createApiModel({
  name: "baitFisher",
  resource: "/royalstar/bait/fishers",
  schema: Type.Object({
    id: Type.String(),
    firstName: Type.String(),
    lastName: Type.String(),
    companyName: Type.Optional(Type.String()),
    counterPointId: Type.Number(),
    enabled: Type.Boolean(),
  }),
});

export const RSBaitOrder = createApiModel({
  name: "baitOrder",
  resource: "/royalstar/bait/orders",
  schema: Type.Object({
    id: Type.String(),
    isNew: Type.Optional(Type.Boolean()),
    fisher: Type.Optional(RSBaitFisher.schema),
    items: Type.Array(RSBaitOrderItem),
    locationKey: Type.String(),
    date: Type.Number(),
    user: User.schema,
    createdAt: Type.Number(),
    updatedAt: Type.Number(),
    syncedToBackend: Type.Boolean(),
    syncedToCounterPoint: Type.Boolean(),
    syncedToCounterPointAt: Type.Optional(Type.Number()),
    syncedToCounterPointTicketId: Type.Optional(Type.String()),
  }),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RSBaitOrder = Static<typeof RSBaitOrder.schema>;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RSBaitFisher = Static<typeof RSBaitFisher.schema>;
