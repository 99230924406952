import { request } from "@tracktile/axiom";
import { useTheme, Text } from "@ui-kitten/components";
import { useEffect, useState } from "react";

import { ZoomableImage } from "./ZoomableImage/ZoomableImage";
import { getConfig } from "../config";
import { useStorage } from "../lib/storage";

export const DisplayDocument = ({ documentKey }: { documentKey: string }) => {
  const theme = useTheme();
  const [token] = useStorage<string>("token");
  const [presignedDocumentUrl, setPresignedDocumentUrl] = useState<string>();

  useEffect(() => {
    const getDocument = async () => {
      const encodedKey = encodeURIComponent(documentKey);
      const [{ url }] = await request<unknown, { url: string }>(
        `${getConfig().api}/storage?key=${encodedKey}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPresignedDocumentUrl(url);
    };
    getDocument();
  });

  if (presignedDocumentUrl) {
    return (
      <ZoomableImage img={presignedDocumentUrl} width={500} height={500} />
    );
  }

  return <Text>Loading Document..</Text>;
};
