import { NavigationProp, useNavigation } from "@react-navigation/native";
import { request } from "@tracktile/axiom";
import moment from "moment";
import { useState } from "react";
import Toast from "react-native-root-toast";
import { v4 as uuid } from "uuid";

import { RootStackParamList } from "../../types";
import {
  useApi,
  StagedDelivery,
  StagedDeliveryItem,
  Fisher,
  Species,
  SpeciesQuantity,
  Vessel,
  Station,
  Deduction,
  DefaultSelections,
} from "../api";
import { getConfig } from "../config";
import { useAuth, useUser } from "../context/authProvider";
import { useStations } from "../context/stationsProvider";
import { useStorage } from "../lib/storage";

export const useLanding = (landingId?: string) => {
  const api = useApi();
  const apiUrl = getConfig().api;
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const user = useUser();
  const { currentStation } = useStations();
  const { tenant } = useAuth();
  const [token] = useStorage<string>("token");
  const { data: landings = [] } = api.StagedDelivery.all();
  const toastOptions = {
    duration: Toast.durations.LONG,
    position: -150,
  };

  const [hasLandingChanged, setHasLandingChanged] = useState<boolean>(false);

  let existingLandingData = api.StagedDelivery.read(landingId ?? "");

  if (existingLandingData) {
    existingLandingData = {
      ...existingLandingData,
      isNew: false,
    };
  }

  const [landing, setLandingState] = useState<StagedDelivery>({
    id: uuid(),
    isNew: true,
    date: new Date().toISOString(),
    batchNumber: moment().format("MMMDDYY"),
    slipNumber: moment().unix().toString(),
    slipDate: moment().format("Y-MM-DD").toString(),
    fishers: [],
    items: [],
    deductions: [],
    vessel: undefined,
    user,
    locationKey: "", // location.key,
    buyingStation: currentStation,
    createdAt: Date.now(),
    updatedAt: Date.now(),
    signatureId: "",
    syncedToBackend: false,
    syncedToSBM: false,
    documents: [],
    ...existingLandingData,
  });

  const [isLoading, setLoadingState] = useState(false);

  const setLanding = (stateFn: (state: StagedDelivery) => StagedDelivery) => {
    setLandingState(stateFn(landing));
    setHasLandingChanged(true);
  };

  const {
    date,
    items: landingItems,
    vessel,
    fishers,
    deductions,
    documents,
    batchNumber,
    slipNumber,
    buyingStation,
    syncedToSBM,
    signatureId,
  } = landing;

  const setDate = (date: Date) => {
    setLanding((landing) => ({ ...landing, date: date.toISOString() }));
  };

  const setDefaultSpeciesAndDeductions = (
    defaultSelections: DefaultSelections
  ) => {
    setLanding((landing) => ({
      ...landing,
      deductions:
        defaultSelections.defaultDeduction.id > 0
          ? [
              {
                itemId: defaultSelections.defaultDeduction.id,
                itemName: defaultSelections.defaultDeduction.name,
                itemDescription:
                  defaultSelections.defaultDeduction.description ?? "",
                quantity: 1,
              },
            ]
          : [],
      items:
        defaultSelections.defaultSpecies.id > 0
          ? [
              {
                id: uuid(),
                species: {
                  id: defaultSelections.defaultSpecies.id.toString(),
                  name: defaultSelections.defaultSpecies.name,
                },
                metadata: {},
                position: landingItems.length,
                count: 1,
              },
            ]
          : [],
    }));
  };

  const addFisher = (fisher: Fisher) => {
    setLanding((landing) => ({
      ...landing,
      fishers: [...landing.fishers, fisher],
    }));
  };

  const updateFisher = (fisherId: string, fisher: Fisher) => {
    setLanding((landing) => ({
      ...landing,
      fishers: landing.fishers.map((f) =>
        String(f.id) === String(fisherId) ? fisher : f
      ),
    }));
  };

  const removeFisher = (fisher: Fisher) => {
    setLanding((landing) => ({
      ...landing,
      fishers: landing.fishers.filter(
        ({ id }) => String(id) !== String(fisher.id)
      ),
    }));
  };

  const addDeduction = (deduction: StagedDelivery["deductions"][number]) => {
    setLanding((landing) => ({
      ...landing,
      deductions: [...(landing.deductions ?? []), deduction],
    }));
  };

  const updateDeduction = (
    deductionId: number,
    deduction: StagedDelivery["deductions"][number]
  ) => {
    setLanding((landing) => ({
      ...landing,
      deductions: landing.deductions.map((d) =>
        Number(d.itemId) === Number(deductionId) ? deduction : d
      ),
    }));
  };

  const setDeductionQuantity = (deductionId: number, quantity: number) => {
    const deduction = landing.deductions.find(
      ({ itemId: id }) => Number(id) === Number(deductionId)
    );
    if (!deduction) return;
    updateDeduction(deductionId, {
      ...deduction,
      quantity,
    });
  };

  const removeDeduction = (deduction: StagedDelivery["deductions"][number]) => {
    setLanding((landing) => ({
      ...landing,
      deductions: landing.deductions.filter(
        ({ itemId }) => Number(itemId) !== Number(deduction.itemId)
      ),
    }));
  };

  const setBatchNumber = (batchNumber: string) => {
    setLanding((landing) => ({ ...landing, batchNumber }));
  };

  const setSlipNumber = (slipNumber: string) => {
    setLanding((landing) => ({ ...landing, slipNumber }));
  };

  const setSyncedToSBM = (syncedToSBM: boolean) => {
    setLanding((landing) => ({ ...landing, syncedToSBM }));
  };

  const setBuyingStation = (buyingStation: Station) => {
    setLanding((landing) => ({
      ...landing,
      buyingStation,
    }));
  };

  const setLandingItems = (items: StagedDeliveryItem[]) =>
    setLanding((landing) => ({
      ...landing,
      items,
    }));

  const setSpeciesForItem = (
    species: Pick<Species, "id" | "name">,
    itemId: string
  ) => {
    const item = landingItems.find(({ id }) => id === itemId);
    if (!item) return;
    setLandingItems([
      ...landingItems.filter(({ id }) => id !== itemId),
      { ...item, species },
    ]);
  };

  const setMetadataForItem = (
    metadata: StagedDeliveryItem["metadata"],
    itemId: string
  ) => {
    const item = landingItems.find(({ id }) => id === itemId);
    if (!item) return;
    setLandingItems([
      ...landingItems.filter(({ id }) => id !== itemId),
      {
        ...item,
        metadata: {
          ...item.metadata,
          ...metadata,
        },
      },
    ]);
  };

  const setVessel = (vessel: Vessel) => {
    setLanding((landing) => ({
      ...landing,
      vessel,
    }));
  };

  const removeVessel = () => {
    setLanding((landing) => ({
      ...landing,
      vessel: undefined,
    }));
  };

  const { mutate: createLandingMutation } = api.StagedDelivery.create();

  const { mutate: updateLandingMutation } = api.StagedDelivery.update({
    onSuccess: () => {
      api.StagedDelivery.invalidateAll();
    },
  });

  const { mutateAsync: deleteLandingMutation } = api.StagedDelivery.remove();

  const setCountForItem = (count: number, itemId: string) => {
    const newItems = landingItems.map((landingItem) => {
      if (landingItem.id !== itemId) {
        return landingItem;
      }

      return {
        ...landingItem,
        count,
      };
    });

    setLandingItems(newItems);
  };

  const addItem = (species: Species, quantity?: SpeciesQuantity) => {
    setLandingItems([
      ...landingItems,
      {
        id: uuid(),
        species: {
          id: species.id,
          name: species.name,
        },
        metadata: {},
        position: landingItems.length,
        count: 1,
      },
    ]);
  };

  const removeItem = (itemId: string) => {
    setLandingItems([...landingItems.filter(({ id }) => id !== itemId)]);
  };

  const resetLanding = () => {
    setLanding(() => ({
      id: uuid(),
      isNew: true,
      date: new Date().toISOString(),
      fishers: [],
      items: [],
      deductions: [],
      batchNumber: moment().format("MMMDDYY"),
      slipNumber: "",
      locationKey: "", //location.key,
      user,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      signatureId: "",
      syncedToBackend: false,
      syncedToSBM: false,
      documents: [],
    }));
  };

  const submitLanding = async () => {
    if (landing.isNew) {
      createLandingMutation(landing);
      Toast.show("Landing added succesfully", toastOptions);
      navigation.navigate("Landings");
      resetLanding();
    } else {
      updateLandingMutation(landing);
      Toast.show("Landing updated succesfully", toastOptions);
      navigation.navigate("Landings");
    }
  };

  const deleteLanding = async () => {
    await deleteLandingMutation(landing);
    Toast.show("Landing removed successfully", toastOptions);
    resetLanding();
  };

  const syncLandingToSBM = async (id: string) => {
    await new Promise((resolve, reject) => {
      request(`${apiUrl}/deliveries/staged/${id}/submit`, {
        method: "POST",
        token,
      })
        .then((res) => {
          Toast.show("Landing synced to SBM successfully", toastOptions);
          api.StagedDelivery.invalidateAll();
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const syncLandingToTracktile = async (id: string) => {
    console.log("Syncing landing to tracktile.", id);
    try {
      await request(`${apiUrl}/integration/tracktile/receive/delivery/${id}`, {
        method: "POST",
        token,
      });
    } catch (err) {
      console.error("Error sending landing to Tracktile");
      console.error(err);
    }
  };

  const syncMultipleLandingsToSBM = async (deliveryIds: string[]) => {
    await new Promise((resolve, reject) => {
      request(`${apiUrl}/deliveries/sync-multiple`, {
        method: "POST",
        body: { deliveryIds },
        token,
      })
        .then((res) => {
          Toast.show(
            "Multiple landings synced to SBM successfully",
            toastOptions
          );
          api.StagedDelivery.invalidateAll();
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const syncMultipleLandingsToTracktile = async (deliveryIds: string[]) => {
    console.log("Syncing multiple landings to tracktile.", deliveryIds);

    async function promiseAllInBatches<T>(
      task: (id: string) => Promise<T>,
      items: string[],
      batchSize: number
    ) {
      let position = 0;
      let results = [] as T[];
      while (position < items.length) {
        const itemsForBatch = items.slice(position, position + batchSize);
        results = [
          ...results,
          ...(await Promise.all(itemsForBatch.map((item) => task(item)))),
        ];
        position += batchSize;
      }
      return results;
    }
    try {
      await promiseAllInBatches(syncLandingToTracktile, deliveryIds, 4);
    } catch (err) {
      console.error("Error sending landings to Tracktile");
      console.error(err);
    }
  };

  const getFishersFromVessel = async (vessel: Vessel) => {
    if (vessel.id) {
      try {
        const [vesselFishers] = await request<Fisher[]>(
          `${apiUrl}/fishers/vessel/${vessel.id}`,
          {
            token,
          }
        );
        setLanding((landing) => ({
          ...landing,
          fishers: [...fishers, ...vesselFishers],
          vessel,
        }));
      } catch (err) {
        Toast.show(
          "Getting fishers from vessel failed. Something went wrong.",
          toastOptions
        );
      }
    } else {
      setLanding((landing) => ({
        ...landing,
        fishers: [],
        vessel,
      }));
    }
  };

  const canCheckout =
    ((slipNumber || tenant?.account?.tunaFunctionality) &&
      !!(
        !tenant?.account?.tunaFunctionality ||
        landingItems.every(
          ({ metadata }) =>
            typeof metadata.tagNumber === "string" &&
            `${metadata.tagNumber}`.length > 0 &&
            typeof metadata.containerId !== "undefined" &&
            metadata.containerId !== ""
        )
      ) &&
      hasLandingChanged &&
      (landingItems.length > 0 || deductions.length > 0) &&
      !!fishers?.length) ??
    -1 > 0;

  const canCancelLanding = landingId
    ? hasLandingChanged
    : (landingItems.length > 0 || !!fishers?.length) ?? -1 > 0;

  return {
    //Methods
    setDate,
    addFisher,
    updateFisher,
    removeFisher,
    setSpeciesForItem,
    setCountForItem,
    setDeductionQuantity,
    setBatchNumber,
    setSlipNumber,
    setBuyingStation,
    setMetadataForItem,
    setSyncedToSBM,
    addItem,
    removeItem,
    addDeduction,
    updateDeduction,
    removeDeduction,
    setVessel,
    removeVessel,
    resetLanding,
    submitLanding,
    deleteLanding,
    setLoadingState,
    syncLandingToTracktile,
    syncMultipleLandingsToTracktile,
    syncLandingToSBM,
    syncMultipleLandingsToSBM,
    getFishersFromVessel,
    setDefaultSpeciesAndDeductions,
    //States
    date,
    fishers,
    items: landingItems,
    deductions: deductions.map((deduction) => ({
      ...deduction,
      quantity: deduction.quantity ?? 1,
    })),
    vessel,
    documents,
    batchNumber,
    slipNumber,
    buyingStation,
    isLoading,
    syncedToSBM,
    signatureId,
    //Conditions
    canCheckout,
    canCancelLanding,
  };
};
