import { ItemSelection } from "@aws-sdk/client-cloudfront";
import {
  faAnchor,
  faCalendar,
  faCheckCircle,
  faCheckDouble,
  faClipboard,
  faClock,
  faEdit,
  faFileInvoice,
  faGlobe,
  faLocation,
  faMapPin,
  faPerson,
  faReceipt,
  faShip,
  faSquareMinus,
  faSquarePlus,
  faTag,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  Layout,
  Button,
  Text,
  Divider,
  StyleService,
  useStyleSheet,
  useTheme,
  CheckBox,
} from "@ui-kitten/components";
import moment from "moment";
import { useMemo, Fragment } from "react";
import { Dimensions, Platform, StyleProp, View } from "react-native";

import { StagedDelivery, Fisher, useApi } from "../api";
import { Icon, Chip } from "../components";
import { useAuth } from "../context/authProvider";
import { useLanding } from "../context/landingProvider";
import { useConfirmation } from "../hooks";
import { LandingsStackParamsList } from "../screens/landings/types";

const themedStyles = StyleService.create({
  container: {
    marginHorizontal: 12,
    marginTop: 24,
    padding: 18,
    display: "flex",
    flexDirection: "column",
    borderRadius: 12,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  grow: {
    flexGrow: 1,
  },
  headerRowLast: {
    flexDirection: "row",
    alignItems: "center",
  },
  headerRowIcon: {
    width: 22,
    height: 22,
    marginRight: 5,
    textAlign: "center",
    alignSelf: "center",
    justifyContent: "center",
  },
  col: {
    flexDirection: "column",
  },
  items: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 12,
  },
  itemRow: {
    flexDirection: "row",
    alignContent: "space-between",
    backgroundColor: "background-basic-color-1",
    borderBottomColor: "color-basic-transparent-400",
    flexWrap: "wrap",
    paddingVertical: 8,
  },
  itemName: {
    alignSelf: "stretch",
  },
  itemCount: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
    marginRight: 20,
  },
  summary: {
    flexDirection: "column",
    paddingHorizontal: 24,
    paddingBottom: 8,
  },
  footer: {
    padding: 8,
    flexDirection: "row",
    alignItems: "center",
  },
});

interface LandingCardProps
  extends Pick<NativeStackScreenProps<LandingsStackParamsList>, "navigation"> {
  delivery: StagedDelivery;
  isCheckboxVisible?: boolean;
  isCheckboxSelected?: boolean;
  onCheckboxChange?: Function;
}

const toName = ({ companyName, firstName, lastName }: Fisher) => {
  if (companyName) {
    return companyName;
  }
  if (firstName && lastName) {
    return `${lastName}, ${firstName}`;
  }
  if (!lastName && firstName) {
    return firstName;
  }
  if (lastName && !firstName) {
    return lastName;
  }
};

export const LandingCard = ({
  delivery,
  navigation,
  isCheckboxVisible = false,
  isCheckboxSelected = false,
  onCheckboxChange,
}: LandingCardProps) => {
  const api = useApi();
  const { tenant } = useAuth();
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const {
    id: deliveryId,
    fishers,
    items,
    deductions = [],
    date,
    user,
    batchNumber,
    buyingStation,
    vessel,
    syncedToBackend = true,
    syncedToSBM,
    createdAt,
    updatedAt,
    slipNumber,
    signatureId,
  } = delivery;

  const { syncLandingToSBM, syncLandingToTracktile } = useLanding();

  const { data: { nodes = [] } = {} } = api.TracktileFlow.call({});

  const containers = nodes.filter(
    (node) => node.behaviour.type === "container"
  );

  const canApprove =
    fishers.length > 0 &&
    items.length > 0 &&
    (!!slipNumber || tenant?.account?.tunaFunctionality) &&
    (tenant?.account?.tunaFunctionality || !!batchNumber || !!date);

  const sortedItems = useMemo(
    () =>
      items.sort(({ count: a }, { count: b }) => (a > b ? -1 : a < b ? 1 : 0)),
    [items]
  );

  const { Modal: SyncConfirmationModal, show: showSyncModal } = useConfirmation(
    {
      title: "Sync to Fisher Account?",
      description:
        "This will upload the details to the fisher accounts. Once synchronized, you cannot edit or delete this delivery. Are you sure?",
      cancelText: "Go Back",
      confirmText: "Sync Delivery to SBM",
      onConfirm: async () => {
        await Promise.all([
          syncLandingToSBM(deliveryId),
          syncLandingToTracktile(deliveryId),
        ]);
      },
    }
  );

  return (
    <Layout
      key={`${deliveryId}-${updatedAt}`}
      level="1"
      style={styles.container}
    >
      <View style={[styles.row, styles.spaceBetween]}>
        <View style={styles.row}>
          {isCheckboxVisible && (
            <CheckBox
              style={{ marginRight: 24 }}
              onChange={(state) => onCheckboxChange(state, deliveryId)}
              checked={isCheckboxSelected}
            />
          )}
          {fishers.map((fisher, index) => (
            <>
              <Text category="h6">{toName(fisher)}</Text>
              {index < fishers.length - 1 && (
                <Text category="s1" style={{ marginHorizontal: 12 }}>
                  •
                </Text>
              )}
            </>
          ))}
        </View>
        {vessel && <Chip icon={faShip} label={vessel.name} />}
      </View>
      <View style={[styles.row, { paddingVertical: 12 }]}>
        {!!slipNumber && <Chip icon={faReceipt} label={slipNumber} />}
        <View style={{ marginHorizontal: 6 }} />
        {!!buyingStation?.name && (
          <Chip icon={faLocation} label={buyingStation?.name} />
        )}
        <View style={{ flexGrow: 1 }} />
        <Chip icon={faPerson} label={user.firstName} />
        <View style={{ marginHorizontal: 6 }} />
        <Chip
          icon={faClock}
          label={moment(createdAt).format("h:mmA DD/MM/YY")}
        />
      </View>
      <Text category="label">Species</Text>
      <View style={styles.items}>
        {sortedItems.map(
          (
            {
              species: { id, name },
              metadata: { tagNumber, containerId },
              count,
            },
            index
          ) => {
            const containerName = containers.find(
              ({ id }) => id === containerId
            )?.name;
            return (
              <Fragment key={`${id}-${index}`}>
                <View
                  style={[
                    styles.itemRow,
                    {
                      ...(index === sortedItems.length
                        ? { borderBottomWidth: 0 }
                        : { borderBottomWidth: 1 }),
                    },
                  ]}
                >
                  <View style={styles.itemName}>
                    <Text
                      category="h6"
                      style={{ width: Dimensions.get("window").width / 2.5 }}
                      numberOfLines={4}
                    >
                      {name}
                    </Text>
                    {!!tenant?.account?.tunaFunctionality && !!tagNumber && (
                      <>
                        <View
                          style={{
                            flexDirection: "row",
                            marginTop: 12,
                            alignItems: "center",
                          }}
                        >
                          <Icon
                            icon={faTag}
                            size={16}
                            style={{ marginHorizontal: 6 }}
                          />
                          <Text category="s1" style={{ marginRight: 12 }}>
                            {tagNumber}
                          </Text>
                          <Text category="s1">{containerName}</Text>
                        </View>
                      </>
                    )}
                  </View>
                  <View style={styles.itemCount}>
                    <Text category="h6" style={{ marginRight: 6 }}>
                      {count}
                    </Text>
                    <Text category="h6">KG</Text>
                    {!!tenant?.account?.tunaFunctionality && (
                      <Text category="h6">LBs</Text>
                    )}
                  </View>
                </View>
              </Fragment>
            );
          }
        )}
      </View>

      {Array.isArray(deductions) && deductions?.length > 0 && (
        <>
          <Text category="label">Deductions</Text>
          <View style={styles.items}>
            {deductions?.map((deduction) => {
              const { itemId, itemName } = deduction;
              return (
                <View style={styles.itemRow} key={itemId}>
                  <View style={styles.itemName}>
                    <Text category="h6">{itemName}</Text>
                  </View>
                  <View style={styles.itemCount}>
                    <Text category="h6" style={{ marginRight: 6 }}>
                      {deduction.quantity}
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>
        </>
      )}

      {!isCheckboxVisible && (
        <View style={[styles.footer, styles.row, styles.grow]}>
          <Layout level="3">
            {Platform.OS === "web" && (
              <Button
                appearance="ghost"
                status={
                  !syncedToBackend
                    ? "warning"
                    : syncedToSBM
                      ? "basic"
                      : "primary"
                }
                size="medium"
                accessoryLeft={(props) => (
                  <Icon
                    {...props}
                    icon={
                      !syncedToBackend
                        ? faWarning
                        : syncedToSBM
                          ? faCheckCircle
                          : faGlobe
                    }
                  />
                )}
                onPress={() => {
                  showSyncModal();
                }}
                disabled={syncedToSBM || !syncedToBackend || !canApprove}
              >
                {!syncedToBackend
                  ? "UNSAVED"
                  : syncedToSBM
                    ? "Approved"
                    : "Approve"}
              </Button>
            )}
            {Platform.OS !== "web" && syncedToSBM && (
              <Button
                appearance="ghost"
                status="basic"
                size="tiny"
                accessoryLeft={(props) => (
                  <Icon {...props} icon={faCheckCircle} />
                )}
                disabled
              >
                Synced
              </Button>
            )}
          </Layout>
          <View style={{ flex: 1 }} />
          <View style={{ marginRight: 10 }}>
            {signatureId ? (
              <Button
                appearance="ghost"
                size="tiny"
                status="primary"
                accessoryLeft={(props) => (
                  <Icon {...props} size={12} icon={faCheckCircle} />
                )}
                onPress={() => {
                  navigation.navigate("Signature", {
                    signatureId,
                    landing: delivery,
                  });
                }}
              >
                SIGNED
              </Button>
            ) : (
              <Button
                appearance="ghost"
                status="primary"
                accessoryLeft={(props) => (
                  <Icon {...props} size={18} icon={faCheckCircle} />
                )}
                onPress={() => {
                  navigation.navigate("Signature", {
                    signatureId: undefined,
                    landing: delivery,
                  });
                }}
              >
                SIGN
              </Button>
            )}
          </View>
          <Layout>
            <Button
              appearance="ghost"
              status="primary"
              accessoryLeft={(props) => (
                <Icon {...props} size={18} icon={faEdit} />
              )}
              onPress={() => {
                navigation.navigate("Landing", { landingId: deliveryId });
              }}
              disabled={syncedToSBM}
            >
              {Platform.OS === "web"
                ? canApprove
                  ? "EDIT DELIVERY"
                  : "COMPLETE DELIVERY"
                : "EDIT"}
            </Button>
          </Layout>
        </View>
      )}
      {isCheckboxVisible && (
        <View style={{ flex: 1 }}>
          <Button
            appearance={isCheckboxSelected ? "filled" : "ghost"}
            status="info"
            accessoryLeft={(props) => (
              <Icon
                {...props}
                icon={isCheckboxSelected ? faCheckCircle : faCheckDouble}
              />
            )}
            onPress={() => {
              onCheckboxChange &&
                onCheckboxChange(!isCheckboxSelected, deliveryId);
            }}
          >
            {isCheckboxSelected ? "SELECTED" : "SELECT"}
          </Button>
        </View>
      )}
      <SyncConfirmationModal />
    </Layout>
  );
};
