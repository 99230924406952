import { Layout, Button } from "@ui-kitten/components";
import { useMemo } from "react";
import { ScrollView } from "react-native";

import { useApi, RSBaitSpecies } from "../api";

export type SortCategory = "FLOUNDER" | "HERRING" | "MACKERL" | "OTHER";

export function sortGroupForName(itemName: string): SortCategory {
  const name = itemName.toLowerCase();
  if (name.includes("flounder")) {
    return "FLOUNDER";
  }
  if (name.includes("herring")) {
    return "HERRING";
  }
  if (name.includes("mackerel")) {
    return "MACKERL";
  }
  return "OTHER";
}

interface SpeciesSelectionModalProps {
  onSelect: (species: RSBaitSpecies) => void;
  hideIds?: string[];
}

export const SpeciesSelectionModal = ({
  onSelect,
  hideIds = [],
}: SpeciesSelectionModalProps) => {
  const api = useApi();

  const { data: species = [] } = api.RSBaitSpecies.all();

  const handleSpeciesSelection = (species: RSBaitSpecies) => {
    onSelect(species);
  };

  const sortedData = useMemo(
    () =>
      species
        .filter((species) => {
          return (
            species.enabled &&
            species.quantities?.some(({ enabled }) => !!enabled)
          );
        })
        .filter(({ id }) => !hideIds.includes(id))
        .sort(({ name: a }, { name: b }) => (a < b ? -1 : a > b ? 1 : 0))
        .sort((a, b) => {
          const groupA = sortGroupForName(a.name);
          const groupB = sortGroupForName(b.name);
          return groupA < groupB ? -1 : groupA > groupB ? 1 : 0;
        }),
    [species]
  );

  return (
    <Layout style={{ flex: 1, flexDirection: "column" }}>
      <ScrollView style={{ paddingHorizontal: 10 }}>
        <Layout style={{ paddingVertical: 18 }}>
          {sortedData.map((species, index) => (
            <Layout
              key={`${species.name}-${index}`}
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingVertical: 6,
              }}
            >
              <Button
                size="large"
                appearance="outline"
                status="primary"
                style={{ flex: 1 }}
                onPress={() => handleSpeciesSelection(species)}
              >
                {species.name}
              </Button>
            </Layout>
          ))}
        </Layout>
      </ScrollView>
    </Layout>
  );
};
