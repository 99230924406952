import { faEdit, faEraser } from "@fortawesome/pro-regular-svg-icons";
import { useNavigation } from "@react-navigation/native";
import {
  NativeStackNavigationProp,
  NativeStackScreenProps,
} from "@react-navigation/native-stack";
import { Layout, Button, Text } from "@ui-kitten/components";
import RNDraw from "expo-draw";
import moment from "moment";
import { useRef, useState, useEffect } from "react";
import { ScrollView, Image, View, Dimensions, Platform } from "react-native";
import { captureRef as takeSnapshotWebAsync } from "react-native-view-shot";

import { RootStackParamList } from "../../../types";
import { StagedDelivery } from "../../api";
import { TopNavigation } from "../../components";
import { Icon } from "../../components/Icon";
import { useSignature } from "../../context/signatureProvider";
import { useConfirmation } from "../../hooks";
import { RootScreenProps } from "../../types";

export const SignatureScreen = ({
  route,
}: Omit<NativeStackScreenProps<RootScreenProps>, "navigation">) => {
  const expoDrawRef = useRef(null);

  const navigation =
    useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const landing = (route.params as { landing?: StagedDelivery })?.landing;
  const signatureId = (route.params as { signatureId?: string })?.signatureId;
  const [isSketchClearing, setIsSketchClearing] = useState(false);

  const {
    isSaved,
    signatureBase64,
    createdAt,
    setSignatureBase64,
    submitSignature,
    retrieveSignature,
  } = useSignature(signatureId, landing);

  useEffect(() => {
    retrieveSignature(signatureId ?? "");
  }, [signatureId]);

  const onClear = () => {
    setIsSketchClearing(true);
    setTimeout(() => setIsSketchClearing(false), 50);
  };

  const Sketcher = (
    <RNDraw
      strokes={[]}
      containerStyle={{
        backgroundColor: "#fff",
        width: Platform.OS === "web" ? 600 : Dimensions.get("window").width - 5,
      }}
      color="#000000"
      onChangeStrokes={() => {
        handleDrawSignature();
      }}
      strokeWidth={4}
      enabled
    />
  );

  const handleDrawSignature = async () => {
    const signatureResult = await takeSnapshotWebAsync(expoDrawRef, {
      result: "data-uri",
      quality: 0.5,
      format: "jpg",
    });

    setSignatureBase64(signatureResult);
  };

  const { Modal: SignConfirmationModal, show: showConfirmModal } =
    useConfirmation({
      title: "Sign Landing Slip?",
      description:
        "Once signed, the signature cannot be updated/removed from this landing slip.",
      cancelText: "Go Back",
      confirmText: "Sign Landing",
      onConfirm: async () => {
        await submitSignature();
      },
    });

  return (
    <Layout level="4" style={{ flexGrow: 1 }}>
      <TopNavigation
        title={signatureId ? `Signed Landing` : "Sign Landing"}
        navigation={navigation}
        enableBackAction
      />
      <ScrollView>
        <Layout style={{ alignItems: "center" }} level="4">
          <Layout
            style={{
              height:
                Platform.OS === "web"
                  ? 450
                  : Dimensions.get("window").height - 170,
              marginBottom: 20,
            }}
            level="4"
          >
            {isSaved ? (
              <>
                <Image
                  source={{ uri: signatureBase64 }}
                  style={{ height: 400, width: 400, marginTop: 10 }}
                />
                <Layout style={{ padding: 10, alignItems: "center" }}>
                  <Text category="s1">
                    Signed:{" "}
                    {moment(createdAt).format("MMM DD, Y hh:mm a").toString()}
                  </Text>
                </Layout>
              </>
            ) : (
              <View
                ref={expoDrawRef}
                style={{
                  height: 400,
                  marginBottom: 20,
                  marginTop: 10,
                }}
                collapsable={false}
              >
                {!isSketchClearing && Sketcher}
              </View>
            )}
          </Layout>
          {!isSaved && (
            <Layout
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 20,
              }}
              level="4"
            >
              <Button
                appearance="ghost"
                status="primary"
                accessoryLeft={(props) => <Icon {...props} icon={faEraser} />}
                onPress={() => {
                  onClear();
                }}
              >
                CLEAR
              </Button>
              <Button
                appearance="ghost"
                status="success"
                accessoryLeft={(props) => <Icon {...props} icon={faEdit} />}
                onPress={() => {
                  showConfirmModal();
                }}
              >
                SIGN
              </Button>
            </Layout>
          )}
          <SignConfirmationModal />
        </Layout>
      </ScrollView>
    </Layout>
  );
};
