import { useState } from "react";
import Toast from "react-native-root-toast";
import { v4 as uuid } from "uuid";

import { useApi, Vessel } from "../api";

export const useVessel = () => {
  const api = useApi();
  const toastOptions = {
    duration: Toast.durations.LONG,
    position: -150,
  };

  const [vessel, setVesselState] = useState<Vessel>({
    name: "",
    number: "",
    active: 1,
    processedBackOffice: 0,
    dynamoId: uuid(),
  });

  const setVessel = (stateFn: (state: Vessel) => Vessel) => {
    setVesselState(stateFn(vessel));
  };

  const { name, number } = vessel;

  const setName = (name: string) => {
    setVessel((vessel) => ({ ...vessel, name }));
  };

  const setNumber = (number: string) => {
    setVessel((vessel) => ({ ...vessel, number }));
  };

  const { mutateAsync: createVesselMutation } = api.Vessel.create();

  const resetVessel = () => {
    setVessel(() => ({
      name: "",
      number: "",
      active: 1,
      processedBackOffice: 0,
      dynamoId: uuid(),
    }));
  };

  const submitVessel = async () => {
    let message = "";
    let result: Vessel | null = null;

    if (!name) {
      message += "Name is required";
    }

    if (!number) {
      if (message) message += "\n";
      message += "Vessel Number is required";
    }

    if (!name || !number) {
      Toast.show(message, toastOptions);
    }

    if (name && number) {
      // result = await createVesselMutation(vessel);
      result = vessel;
      Toast.show("Vessel added successfully", toastOptions);
    }
    return result;
  };

  return {
    //Methods
    setName,
    setNumber,
    submitVessel,
    resetVessel,
    //States
    name,
    number,
  };
};
