import {
  faAnchor,
  faAngleLeft,
  faCalendar,
  faChevronLeft,
  faCity,
  faFish,
  faMapMarker,
  faPlus,
  faPlusCircle,
  faShip,
  faTrash,
  faPercentage,
} from "@fortawesome/pro-regular-svg-icons";
import { useNavigation } from "@react-navigation/native";
import {
  NativeStackNavigationProp,
  NativeStackScreenProps,
} from "@react-navigation/native-stack";
import { request } from "@tracktile/axiom";
import {
  Layout,
  Button,
  Card,
  Datepicker,
  Text,
  Input,
  Divider,
  useTheme,
} from "@ui-kitten/components";
import { useState, useEffect, Fragment } from "react";
import {
  ScrollView,
  View,
  TouchableOpacity,
  Platform,
  Dimensions,
  KeyboardAvoidingView,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import { RootStackParamList } from "../../../types";
import {
  useApi,
  StagedDeliveryItem,
  Fisher,
  Vessel,
  Deduction,
  Species,
  StagedDelivery,
} from "../../api";
import {
  SelectionModal,
  TopNavigation,
  CountInput,
  IconHeader,
  TouchableHighlight,
  DisplayDocument,
  CenteredContainer,
  Icon,
  Select,
  LandingPrint,
} from "../../components";
import { TenantConfiguration, useAuth } from "../../context/authProvider";
import { useDefaultSelections } from "../../context/defaultSelectionsProvider";
import { useFisher } from "../../context/fisherProvider";
import { useLanding } from "../../context/landingProvider";
import { useStations } from "../../context/stationsProvider";
import { useTenantLabels } from "../../context/tenantLabelsProvider";
import { useVessel } from "../../context/vesselProvider";
import { useConfirmation, useModal, Modal } from "../../hooks";
import type { RootScreenProps } from "../../types";

interface LandingSpeciesRowProps {
  item: StagedDeliveryItem;
  showSpeciesSelectionModal: (id?: string | number) => void;
  onSetItemMetadata: (meta: StagedDeliveryItem["metadata"]) => void;
  onSetCount: (count: number) => void;
  onRemove: () => void;
}

const TracktileContainerSelector = ({
  containerId,
  onSetItemMetadata,
}: {
  containerId?: string;
  onSetItemMetadata: (meta: StagedDeliveryItem["metadata"]) => void;
}) => {
  const api = useApi();
  const {
    modalProps: containerSelectionModalProps,
    toggle: toggleContainerSelectionModal,
  } = useModal({
    title: "Select Container",
  });
  const { data: { nodes = [] } = {} } = api.TracktileFlow.call({});

  const containers = nodes.filter(
    (node) => node.behaviour.type === "container"
  );

  return (
    <>
      <Modal {...containerSelectionModalProps}>
        <SelectionModal
          selectKey="id"
          onSelect={(ids: (string | number)[]) => {
            const chosen = containers.find((c) => c.id === ids[0]);
            if (chosen) {
              onSetItemMetadata({ containerId: chosen.id });
            }
            toggleContainerSelectionModal();
          }}
          items={containers}
          renderItem={(container) => (
            <View
              style={{
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                padding: 12,
              }}
            >
              <Text category="s1">{container.name}</Text>
            </View>
          )}
        />
      </Modal>
      <Button
        style={{ marginTop: 16 }}
        appearance={containerId ? "ghost" : "filled"}
        onPress={toggleContainerSelectionModal}
      >
        {!containerId
          ? "Choose Container"
          : containers.find(({ id }) => id === containerId)?.name}
      </Button>
    </>
  );
};

const LandingSpeciesRow = ({
  item: { id, count, species, metadata = {} },
  showSpeciesSelectionModal,
  onSetItemMetadata,
  onSetCount,
  onRemove,
}: LandingSpeciesRowProps) => {
  const { tenant } = useAuth();
  const { labels } = useTenantLabels();

  // Mobile
  if (Dimensions.get("window").width < 920) {
    return (
      <Layout
        style={{
          flexDirection: "column",
          // flexGrow: 1,
          width: "100%",
          marginBottom: 12,
          padding: 12,
          position: "relative",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 6,
          }}
        >
          <TouchableHighlight
            onPress={() => showSpeciesSelectionModal(id)}
            style={{
              display: "flex",
              padding: Platform.OS === "web" ? 24 : 9,
              width: Platform.OS === "web" ? "100%" : 140,
            }}
          >
            <Text category="h5" numberOfLines={5}>{`${species.name}`}</Text>
          </TouchableHighlight>
          <Button
            appearance="ghost"
            status="danger"
            accessoryLeft={(props) => <Icon {...props} icon={faTrash} />}
            onPress={() => onRemove()}
          />
        </View>
        {!!species && (
          <CountInput
            value={count}
            onChange={onSetCount}
            unit={tenant?.account?.tunaFunctionality ? "LBS" : ""}
          />
        )}
        {!!tenant?.account?.tunaFunctionality && (
          <>
            <Input
              label={labels?.deliveryNumber ?? "Tag Number"}
              value={metadata.tagNumber ?? ""}
              onChangeText={(value: string) =>
                onSetItemMetadata({ tagNumber: value })
              }
              style={{ marginVertical: 6 }}
            />
            <TracktileContainerSelector
              containerId={metadata.containerId}
              onSetItemMetadata={onSetItemMetadata}
            />
          </>
        )}
      </Layout>
    );
  }

  return (
    <Layout
      style={{
        flexDirection: Dimensions.get("window").width < 920 ? "column" : "row",
        marginHorizontal: 0,
        alignItems: "center",
        justifyContent: "center",
        padding: 12,
      }}
    >
      <TouchableHighlight
        onPress={() => showSpeciesSelectionModal(id)}
        style={{
          display: "flex",
          padding: Platform.OS === "web" ? 24 : 9,
          width: Platform.OS === "web" ? "100%" : 140,
        }}
      >
        <Text
          category={Platform.OS === "web" ? "h5" : "p2"}
          numberOfLines={5}
        >{`${species.name}`}</Text>
      </TouchableHighlight>
      <View style={{ flex: 1, elevation: 0 }} />
      <Layout style={{ flexDirection: "column" }}>
        <Layout
          style={
            Dimensions.get("window").width > 920
              ? {
                  flexDirection: "row",
                  alignItems: "center",
                }
              : {
                  flexDirection: "column",
                  width: "100%",
                }
          }
        >
          {!!species && (
            <>
              <CountInput
                value={count}
                onChange={onSetCount}
                unit={tenant?.account?.tunaFunctionality ? "LBS" : ""}
              />
            </>
          )}
          <Layout style={{ marginHorizontal: 6 }} />
          <Button
            appearance="ghost"
            status="danger"
            accessoryLeft={(props) => <Icon {...props} icon={faTrash} />}
            onPress={() => onRemove()}
          />
        </Layout>
        {!!tenant?.account?.tunaFunctionality && (
          <Layout style={{ flexDirection: "row" }}>
            <Layout
              style={{
                maxWidth: 200,
                margin: 12,
              }}
            >
              <Input
                label={labels?.deliveryNumber ?? "Tag Number"}
                value={metadata.tagNumber ?? ""}
                onChangeText={(value: string) =>
                  onSetItemMetadata({ tagNumber: value })
                }
              />
            </Layout>
            <TracktileContainerSelector
              containerId={metadata.containerId}
              onSetItemMetadata={onSetItemMetadata}
            />
          </Layout>
        )}
      </Layout>
    </Layout>
  );
};

interface LandingSpeciesMockRowProps {
  onNewItem: () => void;
}

const LandingSpeciesMockRow = ({ onNewItem }: LandingSpeciesMockRowProps) => {
  const { tenant } = useAuth();
  return (
    <View
      style={{
        flexDirection: "row",
        marginHorizontal: 0,
        alignItems: "center",
        marginTop: 10,
      }}
    >
      <View>
        <Button
          size={Platform.OS === "web" ? "large" : "medium"}
          appearance="filled"
          onPress={onNewItem}
          accessoryLeft={(props) => <Icon {...props} icon={faPlusCircle} />}
        >
          {tenant?.account?.tunaFunctionality ? "Add Tuna" : "Select Species"}
        </Button>
      </View>
    </View>
  );
};

const LandingDeductionRow = ({
  deduction,
  onSetQuantity,
  showDeductionSelectionModal,
  removeDeduction,
}: {
  deduction: StagedDelivery["deductions"][number];
  onSetQuantity: (quantity: number) => void;
  showDeductionSelectionModal: () => void;
  removeDeduction: (deduction: StagedDelivery["deductions"][number]) => void;
}) => {
  return (
    <Layout
      style={{
        flexDirection: "row",
        marginHorizontal: 0,
        alignItems: "center",
        justifyContent: "space-between",
        padding: 12,
      }}
    >
      <Layout style={{ flexDirection: "row", flexWrap: "wrap" }}>
        <TouchableHighlight
          onPress={showDeductionSelectionModal}
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 24,
          }}
        >
          <>
            <Text category="label" style={{ marginBottom: 12 }}>
              {deduction.itemName ?? ""}
            </Text>
            <Text
              category={Platform.OS === "web" ? "h5" : "p1"}
              numberOfLines={4}
            >
              {deduction.itemDescription ?? ""}
            </Text>
          </>
        </TouchableHighlight>
      </Layout>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <CountInput
          value={deduction.quantity}
          onChange={onSetQuantity}
          step={1}
        />
        <Button
          appearance="ghost"
          status="danger"
          accessoryLeft={(props) => <Icon {...props} icon={faTrash} />}
          onPress={() => removeDeduction(deduction)}
        />
      </View>
    </Layout>
  );
};

const LandingDeductionMockRow = ({
  onNewDeduction,
}: {
  onNewDeduction: () => void;
}) => (
  <View
    style={{
      flexDirection: "row",
      marginHorizontal: 0,
      alignItems: "center",
      marginTop: 10,
    }}
  >
    <View>
      <Button
        size={Platform.OS === "web" ? "large" : "medium"}
        appearance="filled"
        onPress={onNewDeduction}
        accessoryLeft={(props) => <Icon {...props} icon={faPlusCircle} />}
      >
        Add Deduction
      </Button>
    </View>
  </View>
);

const LandingFisherRow = ({
  fisher,
  showFisherSelectionModal,
  removeFisher,
  tenant,
}: {
  fisher: Fisher;
  showFisherSelectionModal: () => void;
  removeFisher: (fisher: Fisher) => void;
  tenant: TenantConfiguration | undefined;
}) => {
  const fisherHeader = [fisher.vesselName, fisher.companyName]
    .filter((x) => !!x)
    .join(" - ");
  return (
    <Layout
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <TouchableHighlight
        onPress={showFisherSelectionModal}
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          padding: 24,
        }}
      >
        <>
          {tenant && tenant.account?.vesselManagement === false && (
            <Text category="label" style={{ marginBottom: 12 }}>
              {fisherHeader}
            </Text>
          )}
          <Text
            category={Platform.OS === "web" ? "h5" : "p1"}
            numberOfLines={4}
          >{`${fisher.firstName} ${fisher.lastName}`}</Text>
        </>
      </TouchableHighlight>
      <Button
        appearance="ghost"
        status="danger"
        accessoryLeft={(props) => <Icon {...props} icon={faTrash} />}
        onPress={() => removeFisher(fisher)}
      />
    </Layout>
  );
};

const LandingFisherMockRow = ({ onNewFisher }: { onNewFisher: () => void }) => (
  <View
    style={{
      flexDirection: "row",
      marginHorizontal: 0,
      alignItems: "center",
      marginTop: 10,
    }}
  >
    <View>
      <Button
        size={Platform.OS === "web" ? "large" : "medium"}
        appearance="filled"
        onPress={onNewFisher}
        accessoryLeft={(props) => <Icon {...props} icon={faPlusCircle} />}
      >
        Add Fisher
      </Button>
    </View>
  </View>
);

export const LandingScreen = ({
  route,
}: Omit<NativeStackScreenProps<RootScreenProps>, "navigation">) => {
  const api = useApi();
  const { tenant } = useAuth();
  const { currentStation, stations } = useStations();
  const navigation =
    useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const { labels } = useTenantLabels();
  const landingId = (route.params as { landingId?: string })?.landingId;
  const {
    date,
    fishers,
    vessel,
    batchNumber,
    slipNumber,
    buyingStation,
    items,
    documents,
    deductions,
    addItem,
    setBatchNumber,
    setSlipNumber,
    setBuyingStation,
    setMetadataForItem,
    setDeductionQuantity,
    addFisher,
    updateFisher,
    removeFisher,
    addDeduction,
    updateDeduction,
    removeDeduction,
    setSpeciesForItem,
    setCountForItem,
    removeItem,
    resetLanding,
    removeVessel,
    canCancelLanding,
    canCheckout,
    setDefaultSpeciesAndDeductions,
    submitLanding,
    deleteLanding,
    getFishersFromVessel,
  } = useLanding(landingId);

  const {
    firstName,
    lastName,
    companyName,
    email,
    submitFisher,
    resetFisher,
    setFirstName,
    setLastName,
    setCompanyName,
    setEmail,
  } = useFisher();

  const { name, number, submitVessel, resetVessel, setName, setNumber } =
    useVessel();

  const theme = useTheme();

  const { data: allFishers = [] } = api.Fisher.all();
  const { data: allDeductions = [] } = api.Deduction.all();
  const { data: { results: allSpecies = [] } = {} } = api.Species.search({
    fields: [{ name: "active", is: "true" }],
    limit: 9999,
  });
  const { data: allVessels = [] } = api.Vessel.all();
  const { defaultSelections } = useDefaultSelections();

  const [speciesSelectionTargetId, setSpeciesSelectionTargetId] = useState<
    string | undefined
  >();
  const [fisherSelectionTargetId, setFisherSelectionTargetId] = useState<
    string | undefined
  >();
  const [deductionSelectionTargetId, setDeductionSelectionTargetId] = useState<
    number | undefined
  >();
  const [isInlineCreate, setIsInlineCreate] = useState(false);

  const { Modal: CancelConfirmationModal, show: showCancelModal } =
    useConfirmation({
      title: "Cancel Landing?",
      description: "Landing will not be saved.",
      cancelText: "Go Back",
      confirmText: "Cancel Landing",
      onConfirm: () => {
        resetLanding();
        if (typeof landingId !== "undefined") {
          navigation.navigate("Landings");
        }
        api.StagedDelivery.invalidateAll();
      },
    });

  const { Modal: DeleteConfirmationModal, show: showDeleteModal } =
    useConfirmation({
      title: "Delete Landing?",
      description: "Once deleted, this landing cannot be recovered.",
      cancelText: "Go Back",
      confirmText: "Delete Landing",
      onConfirm: async () => {
        await deleteLanding();
        api.StagedDelivery.invalidateAll();
        navigation.navigate("Landings");
      },
    });

  const {
    modalProps: fisherSelectionModalProps,
    toggle: toggleFisherSelectionModal,
  } = useModal({
    title: "Select Fisher",
  });

  const {
    toggle: toggleSpeciesSelectionModal,
    modalProps: speciesSelectionModalProps,
  } = useModal({
    title: `Select ${tenant?.account?.tunaFunctionality ? "Tuna" : "Species"}`,
  });

  const {
    toggle: toggleVesselSelectionModal,
    modalProps: vesselSelectionModalProps,
  } = useModal({
    title: "Select Vessel",
  });

  const {
    toggle: toggleDeductionSelectionModal,
    modalProps: deductionSelectionModalProps,
  } = useModal({
    title: `Select Deduction`,
  });

  const handleFisherSelection = ([fisherId]: (string | number)[]) => {
    const fisher = allFishers.find(({ id }) => String(id) === String(fisherId));
    if (!fisher) {
      return;
    }
    if (fisherSelectionTargetId) {
      updateFisher(fisherSelectionTargetId, fisher);
    } else {
      addFisher(fisher);
    }
    toggleFisherSelectionModal();
  };

  const handleFisherCreatedInline = (fisher: Fisher) => {
    if (!fisher) {
      return;
    }

    addFisher(fisher);
    toggleFisherSelectionModal();
  };

  const handleSpeciesSelection = ([speciesId]: (string | number)[]) => {
    const species = allSpecies.find(
      ({ id }) => String(id) === String(speciesId)
    );
    if (!species) {
      return;
    }
    if (speciesSelectionTargetId) {
      setSpeciesForItem(species, speciesSelectionTargetId);
    } else {
      addItem(species);
    }
    toggleSpeciesSelectionModal();
  };

  const handleDeductionSelection = ([deductionId]: (string | number)[]) => {
    const deduction = allDeductions.find(
      ({ itemId }) => Number(itemId) === Number(deductionId)
    );
    if (!deduction) {
      return;
    }
    if (deductionSelectionTargetId) {
      updateDeduction(deductionSelectionTargetId, {
        ...deduction,
        quantity:
          (deduction as StagedDelivery["deductions"][number])?.quantity ?? 1,
      });
    } else {
      addDeduction({ ...deduction, quantity: 1 });
    }
    toggleDeductionSelectionModal();
  };

  const handleVesselSelection = ([vesselId]: (string | number)[]) => {
    const selectedVessel = allVessels.find(
      ({ id }) => String(id) === String(vesselId)
    );
    if (!selectedVessel) {
      return;
    }
    // Set fishers on the vessel
    getFishersFromVessel(selectedVessel);
    toggleVesselSelectionModal();
  };

  const handleVesselCreatedInline = (vessel: Vessel) => {
    if (!vessel) {
      return;
    }

    getFishersFromVessel(vessel);
    toggleVesselSelectionModal();
  };

  useEffect(() => {
    if (typeof landingId === "undefined") {
      toggleFisherSelectionModal();
    }
  }, []);

  useEffect(() => {
    if (
      tenant?.account?.tunaFunctionality &&
      items.length === 0 &&
      allSpecies.length === 1
    ) {
      addItem(allSpecies[0]);
    }
  }, [tenant?.account?.tunaFunctionality, items, allSpecies]);

  useEffect(() => {
    // If new landing, then perform default species and deduction item selection.
    if (!landingId) {
      setDefaultSpeciesAndDeductions(defaultSelections);
    }
  }, [landingId]);

  return (
    <Layout level="4" style={{ flexGrow: 1 }}>
      <TopNavigation
        title={landingId ? `Edit Landing` : "New Landing"}
        navigation={navigation}
        showMenuAction
      />
      <Card style={{ paddingHorizontal: 0, minHeight: 82 }}>
        <CenteredContainer>
          <Layout
            style={{
              padding: 0,
              flexDirection: "row",
            }}
          >
            <Layout style={{ flexDirection: "row", flex: 1 }}>
              <Layout
                style={{
                  flex: 1,
                  maxWidth: 200,
                  paddingRight: 10,
                }}
              >
                <Input
                  label={labels?.batchNumber ?? "Batch Number"}
                  value={batchNumber}
                  onChangeText={setBatchNumber}
                />
              </Layout>
            </Layout>
            {!tenant?.account?.tunaFunctionality && (
              <Layout
                style={{
                  flex: 1,
                  maxWidth: 200,
                }}
              >
                <Input
                  label={labels?.deliveryNumber ?? "Slip Number"}
                  value={slipNumber}
                  onChangeText={setSlipNumber}
                />
              </Layout>
            )}
          </Layout>
        </CenteredContainer>
      </Card>
      <Card style={{ paddingHorizontal: 0, minHeight: 82 }}>
        <CenteredContainer>
          <Layout
            style={{
              padding: 0,
              flexDirection: "row",
            }}
          >
            <Layout style={{ flexDirection: "row", flex: 1 }}>
              <Layout
                style={{
                  flex: 1,
                  maxWidth: 200,
                  paddingRight: 10,
                }}
              >
                {Platform.OS === "web" && (
                  <Select
                    options={stations
                      .filter(({ active }) => !!active)
                      .map(({ id, name }) => ({
                        label: name,
                        value: id,
                      }))}
                    summarizeModelName="Station"
                    value={
                      Platform.OS === "web"
                        ? buyingStation?.id
                        : currentStation?.id
                    }
                    disabled={Platform.OS !== "web" && !!landingId}
                    onSelect={(e) => {
                      const [_id] = e;
                      const station = stations.find(({ id }) => id === _id);
                      if (!station) {
                        return;
                      }
                      setBuyingStation(station);
                    }}
                  />
                )}
                {Platform.OS !== "web" && (
                  <>
                    <Text category="label" appearance="hint">
                      Buying Station
                    </Text>
                    <Button
                      appearance="outline"
                      accessoryLeft={(props) => (
                        <Icon {...props} icon={faMapMarker} />
                      )}
                      size="small"
                      onPress={() => {
                        navigation.navigate("StationSelection");
                      }}
                      style={{ marginTop: 5 }}
                    >
                      {currentStation?.name ?? "Select Station"}
                    </Button>
                  </>
                )}
              </Layout>
            </Layout>

            <Layout style={{ flex: 1, maxWidth: 200 }}>
              <Datepicker
                label={labels?.deliveryDate ?? "Landing Date"}
                status="info"
                date={new Date(date)}
                accessoryLeft={(props) => (
                  <Icon {...props} icon={faCalendar} size={16} />
                )}
                disabled
              />
            </Layout>

            {landingId && (
              <Button
                appearance="outline"
                size="small"
                status="danger"
                style={{ marginLeft: 18 }}
                onPress={() => showDeleteModal()}
                accessoryLeft={(props) => <Icon {...props} icon={faTrash} />}
              />
            )}

            <LandingPrint
              {...{
                batchNumber,
                date,
                deductions,
                fishers,
                items,
                vessel,
                buyingStation,
                slipNumber,
                canCheckout: canCheckout || !!landingId,
              }}
            />
          </Layout>
        </CenteredContainer>
      </Card>
      <ScrollView
        style={{
          flexGrow: 1,
          padding: 24,
          height: Dimensions.get("window").height / 2,
        }}
      >
        <CenteredContainer>
          <View style={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
            <View
              style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            >
              {tenant && tenant.account?.vesselManagement && (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 12,
                    marginBottom: 24,
                  }}
                >
                  <Icon
                    icon={faShip}
                    size={Platform.OS === "web" ? 28 : 24}
                    color={theme["color-primary-300"]}
                  />
                  <Button
                    style={{ marginLeft: 12 }}
                    size={Platform.OS === "web" ? "large" : "medium"}
                    appearance={vessel ? "filled" : "outline"}
                    onPress={() => {
                      toggleVesselSelectionModal();
                    }}
                  >
                    {vessel ? vessel.name : "Choose Vessel"}
                  </Button>
                  {vessel && (
                    <Button
                      appearance="ghost"
                      status="danger"
                      accessoryLeft={(props) => (
                        <Icon {...props} icon={faTrash} />
                      )}
                      onPress={() => removeVessel()}
                    />
                  )}
                </View>
              )}

              <IconHeader icon={faAnchor} text="Fishers" />
              <Layout style={{ padding: 14, marginBottom: 24 }}>
                {fishers.map((fisher, index) => (
                  <Fragment key={fisher.id}>
                    <LandingFisherRow
                      fisher={fisher}
                      removeFisher={removeFisher}
                      showFisherSelectionModal={() => {
                        setFisherSelectionTargetId(String(fisher.id));
                        toggleFisherSelectionModal();
                      }}
                      tenant={tenant}
                    />
                    <Divider />
                  </Fragment>
                ))}
                <LandingFisherMockRow
                  onNewFisher={() => {
                    setFisherSelectionTargetId(undefined);
                    toggleFisherSelectionModal();
                  }}
                />
              </Layout>
              <IconHeader
                icon={faFish}
                text={tenant?.account?.tunaFunctionality ? "Tuna" : "Species"}
              />
              <Layout style={{ padding: 14, marginBottom: 24 }}>
                {items.map((item: StagedDeliveryItem, index) => (
                  <Fragment key={item.id}>
                    <LandingSpeciesRow
                      item={item}
                      showSpeciesSelectionModal={(id) => {
                        if (typeof id !== "undefined") {
                          setSpeciesSelectionTargetId(String(id));
                        }
                        toggleSpeciesSelectionModal();
                      }}
                      onSetCount={(count) => setCountForItem(count, item.id)}
                      onSetItemMetadata={(metadata) =>
                        setMetadataForItem(metadata, item.id)
                      }
                      onRemove={() => removeItem(item.id)}
                    />
                    <Divider style={{}} />
                  </Fragment>
                ))}
                <LandingSpeciesMockRow
                  onNewItem={() => {
                    setSpeciesSelectionTargetId(undefined);
                    toggleSpeciesSelectionModal();
                  }}
                />
              </Layout>
              <IconHeader icon={faPercentage} text="Deductions" />
              <Layout style={{ padding: 14, marginBottom: 24 }}>
                {deductions.map(
                  (deduction: StagedDelivery["deductions"][number], index) => (
                    <Fragment key={deduction.itemId}>
                      <LandingDeductionRow
                        deduction={deduction}
                        onSetQuantity={(quantity) => {
                          setDeductionQuantity(deduction.itemId, quantity);
                        }}
                        removeDeduction={removeDeduction}
                        showDeductionSelectionModal={() => {
                          setDeductionSelectionTargetId(
                            Number(deduction.itemId)
                          );
                          toggleDeductionSelectionModal();
                        }}
                      />
                      <Divider />
                    </Fragment>
                  )
                )}
                <LandingDeductionMockRow
                  onNewDeduction={() => {
                    setDeductionSelectionTargetId(undefined);
                    toggleDeductionSelectionModal();
                  }}
                />
              </Layout>
            </View>
            {Platform.OS === "web" && documents.length > 0 && (
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 48,
                  flexGrow: 1,
                  maxWidth: "60%",
                }}
              >
                {documents.map((document) => (
                  <DisplayDocument
                    key={document.key}
                    documentKey={document.key}
                  />
                ))}
              </View>
            )}
          </View>
        </CenteredContainer>
      </ScrollView>
      <SafeAreaView
        edges={["bottom"]}
        style={{
          flexDirection: "row",
          flexGrow: 0,
          minHeight: 42,
        }}
      >
        <CenteredContainer flexDirection="row">
          <Layout
            style={{
              flex: 1,
            }}
          >
            {canCancelLanding && (
              <Button
                size={Platform.OS === "web" ? "giant" : "medium"}
                status="danger"
                accessoryLeft={(props) => <Icon {...props} icon={faTrash} />}
                onPress={() => showCancelModal()}
              >
                Cancel
              </Button>
            )}
            {!canCancelLanding && (
              <Button
                accessoryLeft={(props) => (
                  <Icon {...props} icon={faChevronLeft} />
                )}
                onPress={() => {
                  resetLanding();
                  navigation.goBack();
                }}
                size={Platform.OS === "web" ? "giant" : "medium"}
              >
                Go Back
              </Button>
            )}
          </Layout>
          <Layout
            style={{
              flex: 1,
            }}
          >
            <Button
              accessoryLeft={(props) => <Icon {...props} icon={faPlusCircle} />}
              disabled={!canCheckout}
              size={Platform.OS === "web" ? "giant" : "medium"}
              onPress={() => {
                submitLanding();
              }}
            >
              {landingId ? "Update Landing" : "Submit Landing"}
            </Button>
          </Layout>
        </CenteredContainer>
      </SafeAreaView>
      <Modal {...fisherSelectionModalProps}>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={{ flex: 1 }}
        >
          {isInlineCreate ? (
            <>
              <Layout style={{ flex: 1, flexDirection: "column", padding: 12 }}>
                <Layout>
                  <Text style={{ padding: 8 }}>Add New Fisher</Text>
                  <Input
                    style={{ padding: 8 }}
                    label="First Name"
                    value={firstName}
                    onChangeText={setFirstName}
                  />
                  <Input
                    style={{ padding: 8 }}
                    label="Last Name"
                    value={lastName}
                    onChangeText={setLastName}
                  />
                  <Input
                    style={{ padding: 8 }}
                    label="Company Name (optional)"
                    value={companyName}
                    onChangeText={setCompanyName}
                  />
                  <Input
                    style={{ padding: 8 }}
                    label="E-mail (optional)"
                    value={email}
                    onChangeText={setEmail}
                  />
                </Layout>
              </Layout>
            </>
          ) : (
            <SelectionModal
              selectKey="id"
              onSelect={handleFisherSelection}
              items={allFishers
                .filter((fisher) => !!fisher.enabled)
                .filter((fisher) => {
                  console.log({
                    "fisher.station": fisher.station,
                    buyingStation,
                    va: String(fisher.station) === String(buyingStation?.id),
                  });
                  return String(fisher.station) === String(buyingStation?.id);
                })
                .filter(({ id }) => !fishers.map(({ id }) => id).includes(id))}
              renderItem={(fisher) => (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 12,
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexGrow: 1,
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text category="s1">{`${fisher.firstName} ${fisher.lastName}`}</Text>

                    {fisher.companyName && (
                      <View style={{ flexDirection: "row" }}>
                        <Icon icon={faCity} size={12} style={{ padding: 3 }} />
                        <Text category="s2">{fisher.companyName}</Text>
                      </View>
                    )}

                    {tenant && tenant.account?.vesselManagement === false && (
                      <>
                        {fisher.vesselName && (
                          <View style={{ flexDirection: "row" }}>
                            <Icon
                              icon={faShip}
                              size={12}
                              style={{ padding: 3 }}
                            />
                            <Text category="s2">{fisher.vesselName}</Text>
                          </View>
                        )}
                      </>
                    )}
                  </View>
                </View>
              )}
            />
          )}
          {isInlineCreate && (
            <Button
              onPress={async () => {
                const result = await submitFisher();
                if (result) {
                  handleFisherCreatedInline(result);
                  setIsInlineCreate(!isInlineCreate);
                }
              }}
              accessoryLeft={(props) => <Icon {...props} icon={faPlusCircle} />}
            >
              Submit
            </Button>
          )}
          <Button
            onPress={() => {
              setIsInlineCreate(!isInlineCreate);
              resetFisher();
            }}
            style={{ marginBottom: 12 }}
            appearance="ghost"
            accessoryLeft={(props) =>
              isInlineCreate ? (
                <Icon {...props} icon={faAngleLeft} />
              ) : (
                <Icon {...props} icon={faPlus} />
              )
            }
          >
            {isInlineCreate ? "Go Back" : "Add New Fisher"}
          </Button>
        </KeyboardAvoidingView>
      </Modal>
      <Modal {...speciesSelectionModalProps}>
        <SelectionModal
          selectKey="id"
          onSelect={handleSpeciesSelection}
          items={allSpecies
            .filter((species) => !!species.enabled)
            .filter(
              (species) => !species.name.toLowerCase().includes("price change")
            )}
          renderItem={(species) => (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 12,
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Text category="s1">{species.name}</Text>
              </View>
            </View>
          )}
        />
      </Modal>
      <Modal {...deductionSelectionModalProps}>
        <SelectionModal
          selectKey="itemId"
          onSelect={handleDeductionSelection}
          items={allDeductions.filter(
            ({ itemId }) =>
              !deductions.map(({ itemId }) => itemId).includes(itemId)
          )}
          renderItem={(deduction) => (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 12,
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Text category="s1">{deduction.itemName}</Text>

                {deduction.itemDescription && (
                  <Text category="s2">{deduction.itemDescription}</Text>
                )}
              </View>
            </View>
          )}
        />
      </Modal>
      <Modal {...vesselSelectionModalProps}>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={{ flex: 1 }}
        >
          {isInlineCreate ? (
            <>
              <Layout style={{ flex: 1, flexDirection: "column", padding: 12 }}>
                <Layout>
                  <Text style={{ padding: 8 }}>Add New Vessel</Text>
                  <Input
                    style={{ padding: 8 }}
                    label="Name"
                    value={name}
                    onChangeText={setName}
                  />
                  <Input
                    style={{ padding: 8 }}
                    label="Vessel Number"
                    value={number}
                    onChangeText={setNumber}
                  />
                </Layout>
              </Layout>
            </>
          ) : (
            <SelectionModal
              selectKey="id"
              onSelect={handleVesselSelection}
              items={allVessels.filter((vessel) => !!vessel.active)}
              renderItem={(vessel) => (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 12,
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexGrow: 1,
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text category="s1">{vessel.name}</Text>

                    {vessel.number && (
                      <View style={{ flexDirection: "row" }}>
                        <Icon icon={faShip} size={12} style={{ padding: 3 }} />
                        <Text category="s2">{vessel.number}</Text>
                      </View>
                    )}
                  </View>
                </View>
              )}
            />
          )}
          {isInlineCreate && (
            <Button
              onPress={async () => {
                const result = await submitVessel();
                if (result) {
                  handleVesselCreatedInline(result);
                  setIsInlineCreate(!isInlineCreate);
                }
              }}
              accessoryLeft={(props) => <Icon {...props} icon={faPlusCircle} />}
            >
              Submit
            </Button>
          )}
          <Button
            onPress={() => {
              setIsInlineCreate(!isInlineCreate);
              resetVessel();
            }}
            style={{ marginBottom: 12 }}
            appearance="ghost"
            accessoryLeft={(props) =>
              isInlineCreate ? (
                <Icon {...props} icon={faAngleLeft} />
              ) : (
                <Icon {...props} icon={faPlus} />
              )
            }
          >
            {isInlineCreate ? "Go Back" : "Add New Vessel"}
          </Button>
        </KeyboardAvoidingView>
      </Modal>
      <CancelConfirmationModal />
      <DeleteConfirmationModal />
    </Layout>
  );
};
