import { MutationCache, QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 4,
      staleTime: Infinity,
      gcTime: 1000 * 60 * 60 * 24,
      refetchInterval: 1000 * 60,
    },
    mutations: {
      retry: true,
    },
  },
  mutationCache: new MutationCache({}),
});
