import { createApiModel, Type as T, Static } from "@tracktile/axiom";

export const SpeciesQuantity = T.Object({
  value: T.Number(),
  unit: T.String(),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SpeciesQuantity = Static<typeof SpeciesQuantity>;

export const Species = createApiModel({
  name: "species",
  resource: "/species",
  schema: T.Object({
    id: T.String(),
    name: T.String(),
    enabled: T.Boolean(),
    quantities: T.Array(SpeciesQuantity),
  }),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Species = Static<typeof Species.schema>;
