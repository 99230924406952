import { faPrint } from "@fortawesome/pro-regular-svg-icons";
import { Button } from "@ui-kitten/components";
import * as Print from "expo-print";
import { shareAsync } from "expo-sharing";
import moment from "moment";
import { useMemo, useEffect } from "react";
import { Platform } from "react-native";

import { StagedDelivery } from "../api";
import { Icon } from "../components";
import { useSignature } from "../context/signatureProvider";

export const LandingPrint = ({
  date,
  fishers,
  vessel,
  slipNumber,
  buyingStation,
  items,
  deductions,
  signatureId,
  canCheckout,
}: Pick<
  StagedDelivery,
  | "date"
  | "fishers"
  | "vessel"
  | "slipNumber"
  | "buyingStation"
  | "items"
  | "deductions"
  | "signatureId"
> & { canCheckout: boolean }) => {
  const { signatureBase64, retrieveSignature } = useSignature(signatureId);

  useEffect(() => {
    retrieveSignature(signatureId ?? "");
  }, [signatureId]);

  const buyingStationHTML = buyingStation
    ? `<h2>Buying Station: ${buyingStation.name}</h2>`
    : "";

  const vesselHTML = vessel ? `<h2>Vessel: ${vessel.name}</h2>` : "";

  const sortedItems = useMemo(
    () =>
      items.sort(({ count: a }, { count: b }) => (a > b ? -1 : a < b ? 1 : 0)),
    [items]
  );

  const fishersHTML = fishers
    .map(
      ({ firstName, lastName, companyName }) =>
        `<tr><td>${firstName} ${lastName}</td><td>${
          vessel?.name ?? ""
        }</td><td>${companyName}</td></tr>`
    )
    .join("");

  const itemsHTML = sortedItems
    .map(
      ({ species, count }) =>
        `<tr><td>${species.name}</td><td>${count}</td></tr>`
    )
    .join("");

  const deductionsHTML = deductions
    .map(
      ({ itemName, itemDescription, quantity }) =>
        `<tr><td>${itemName}</td><td>${itemDescription}</td><td>${quantity}</td></tr>`
    )
    .join("");

  const signatureHTML = signatureBase64
    ? `<img src="${signatureBase64}" alt="Signature not found" style="height: 400px;"/>`
    : "";

  const baseHTML = `
  <html>
    <head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no" />

      <style>
        table {
            border: 1px solid black;
            border-collapse: collapse;
        }
        th, td {
            padding: 5px;
        }
      </style>
    </head>
    <body style="padding: 20px; font-family: Helvetica Neue; font-weight: normal;">
      <h2>Date: ${moment(date).format("MMMM DD, Y").toString()}</h3>
      <h2>Slip Number: ${slipNumber}</h3>
      ${buyingStationHTML}
      ${vesselHTML}
      
      <table style="width: 100%; margin-bottom: 20px;">
        <thead>
            <tr>
                <th style="text-align: center;" colspan="3">FISHERS</th>
            </tr>
            <tr>
                <th style="text-align: left;">Name</th>
                <th style="text-align: left;">Vessel</th>
                <th style="text-align: left;">Company</th>
            </tr>
        </thead>
        <tbody>
            ${fishersHTML}
        </tbody>
      </table>

      <table style="width: 100%; margin-bottom: 20px;">
        <thead>
            <tr>
                <th style="text-align: center;" colspan="3">SPECIES</th>
            </tr>
            <tr>
                <th style="text-align: left;">Name</th>
                <th style="text-align: left;">Count</th>
            </tr>
        </thead>
        <tbody>
            ${itemsHTML}
        </tbody>
      </table>

      <table style="width: 100%; margin-bottom: 20px;">
        <thead>
            <tr>
                <th style="text-align: center;" colspan="3">DEDUCTIONS</th>
            </tr>
            <tr>
                <th style="text-align: left;">Name</th>
                <th style="text-align: left;">Description</th>
            </tr>
        </thead>
        <tbody>
            ${deductionsHTML}
        </tbody>
      </table>

      ${signatureHTML}
    </body>
  </html>
  `;

  const print = async () => {
    if (Platform.OS === "web") {
      // Hacky solution for web since expo-print only prints the entire current page on web.
      const pW = window.open("", "", "height=500, width=500");
      if (pW) {
        pW.document.write(baseHTML);
        pW.document.close();
        pW.print();
      }
    } else {
      const { uri } = await Print.printToFileAsync({ html: baseHTML });
      console.log("File has been saved to:", uri);
      await shareAsync(uri, { UTI: ".pdf", mimeType: "application/pdf" });
    }
  };

  return (
    <Button
      appearance="outline"
      size="small"
      disabled={!canCheckout}
      status="basic"
      style={{ marginLeft: 18 }}
      onPress={print}
      accessoryLeft={(props) => <Icon {...props} icon={faPrint} />}
    />
  );
};
