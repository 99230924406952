import { faScannerGun } from "@fortawesome/pro-regular-svg-icons";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useMutation } from "@tanstack/react-query";
import {
  Button,
  Select,
  Input,
  Text,
  Layout,
  SelectItem,
  IndexPath,
} from "@ui-kitten/components";
import { Image } from "expo-image";
import moment from "moment";
import { useState, useMemo } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import DocumentScanner from "react-native-document-scanner-plugin";
import { SafeAreaView } from "react-native-safe-area-context";
import { v4 as uuid } from "uuid";

import { TunaStackParamsList } from "./types";
import { StagedDelivery, Station, User, useApi } from "../../api";
import { AddTunaCall } from "../../api/tuna";
import { Icon, TopNavigation } from "../../components";
import { useUser } from "../../context/authProvider";
import { useStations } from "../../context/stationsProvider";
import { useStorage } from "../../lib/storage";

const getExtension = (filename: string) =>
  filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
  filename;

const styles = StyleSheet.create({
  scrollView: {},
  stationSelect: {
    marginHorizontal: 12,
  },
  tagNumberInput: {
    marginHorizontal: 12,
    marginVertical: 24,
  },
  containerSelect: {
    flexGrow: 1,
    marginHorizontal: 12,
  },
  scanButton: {
    marginHorizontal: 12,
    marginVertical: 24,
  },
  submitButton: {},
});

export const NewTunaScreen = ({
  navigation,
}: NativeStackScreenProps<TunaStackParamsList>) => {
  const api = useApi();
  const user = useUser();
  const [token] = useStorage<string>("token");

  const [containerId, setContainerId] = useState<string>();
  const [scannedImage, setScannedImage] = useState<string>();
  const [tagNumber, setTagNumber] = useState<string>();

  const {
    stations: allStations,
    currentStation,
    setCurrentStation,
  } = useStations();
  const { data: { nodes = [] } = {}, isLoading: isLoadingContainers } =
    api.TracktileFlow.call({});

  const { mutate: createStagedDeliveryWithFileUpload } = useMutation<
    StagedDelivery,
    Error,
    AddTunaCall
  >({ mutationKey: ["createStagedDeliveryWithFileUpload"] });

  const stations = useMemo(
    () => allStations.sort(({ name: a }, { name: b }) => a.localeCompare(b)),
    [allStations]
  );

  const containers = useMemo(
    () =>
      nodes
        .filter((node) => node.behaviour.type === "container")
        .sort(({ name: a }, { name: b }) => a.localeCompare(b)),
    [nodes]
  );

  const canSubmit = useMemo(
    () => scannedImage && tagNumber && currentStation && containerId,
    [scannedImage, tagNumber, currentStation, containerId]
  );

  const handleStationSelect = (indexPath: IndexPath | IndexPath[]) => {
    const index = Array.isArray(indexPath) ? indexPath[0].row : indexPath.row;
    setCurrentStation(stations?.[index]);
  };

  const handleContainerSelect = (indexPath: IndexPath | IndexPath[]) => {
    const index = Array.isArray(indexPath) ? indexPath[0].row : indexPath.row;
    setContainerId(containers?.[index].id);
  };

  const handleScanPressed = async () => {
    const { scannedImages } = await DocumentScanner.scanDocument();
    if (typeof scannedImages !== "undefined" && scannedImages.length > 0) {
      setScannedImage(scannedImages[0]);
    }
  };

  const handleSubmit = () => {
    if (!user) return;
    if (!currentStation) return;
    if (!containerId) return;
    if (!scannedImage) return;
    if (!tagNumber) return;
    if (!token) return;

    const newId = uuid();

    createStagedDeliveryWithFileUpload({
      token,
      containerId,
      station: currentStation,
      user,
      tagNumber,
      fileUri: scannedImage,
      extension: getExtension(scannedImage),
      fileKey: `${tagNumber}-${Date.now()}.${getExtension(scannedImage)}`,
      stagedDelivery: {
        id: newId,
        isNew: true,
        date: new Date().toISOString(),
        batchNumber: moment().format("MMMDDYY"),
        slipNumber: `${tagNumber}`,
        slipDate: moment().format("Y-MM-DD").toString(),
        fishers: [],
        deductions: [],
        items: [
          {
            id: uuid(),
            position: 1,
            count: 1,
            species: {
              id: "1",
              name: "Bluefin Tuna",
            },
            metadata: {
              containerId,
              tagNumber,
            },
          },
        ],
        vessel: undefined,
        user,
        locationKey: currentStation?.name,
        buyingStation: currentStation,
        createdAt: Date.now(),
        updatedAt: Date.now(),
        signatureId: "",
        syncedToBackend: false,
        syncedToSBM: false,
        documents: [],
      },
    });
    navigation.goBack();
  };

  return (
    <Layout style={{ flex: 1 }}>
      <TopNavigation
        navigation={navigation}
        title="New Tuna"
        enableBackAction
      />
      <Layout style={{ flex: 1 }}>
        <ScrollView style={styles.scrollView}>
          <Select
            key={currentStation?.id}
            style={styles.stationSelect}
            placeholder="Location"
            label="Location"
            value={currentStation?.name}
            onSelect={handleStationSelect}
          >
            {stations.map(({ id, name }) => (
              <SelectItem key={id} title={name} />
            ))}
          </Select>
          <Input
            style={styles.tagNumberInput}
            keyboardType="number-pad"
            label="Tag Number"
            placeholder="Tag Number"
            value={tagNumber}
            onChangeText={setTagNumber}
          />
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Select
              style={styles.containerSelect}
              placeholder="Slush Tank"
              label="Slush Tank"
              value={containers.find(({ id }) => id === containerId)?.name}
              onSelect={handleContainerSelect}
            >
              {containers.map(({ id, name }) => (
                <SelectItem key={id} title={name} />
              ))}
            </Select>
            {!!isLoadingContainers && <Text>Is Loading..</Text>}
          </View>
          {!!scannedImage && (
            <View
              style={{
                marginTop: 24,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Image
                style={{
                  width: 800 / 4,
                  height: 1000 / 4,
                }}
                source={{ uri: scannedImage }}
                contentFit="cover"
                transition={1000}
              />
            </View>
          )}
          <Button
            style={styles.scanButton}
            accessoryLeft={(props) => <Icon icon={faScannerGun} {...props} />}
            appearance="outline"
            onPress={handleScanPressed}
          >
            {scannedImage ? "Rescan ICCAT" : "Scan ICCAT"}
          </Button>
        </ScrollView>
      </Layout>
      <SafeAreaView edges={["bottom"]}>
        <Button
          style={styles.submitButton}
          disabled={!canSubmit}
          onPress={handleSubmit}
        >
          Submit
        </Button>
      </SafeAreaView>
    </Layout>
  );
};
