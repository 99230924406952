import { createApiModel, Type, Static } from "@tracktile/axiom";

// eslint-disable-next-line @typescript-eslint/no-redeclare
const DefaultSelectionSchema = Type.Object({
  defaultDeduction: Type.Object({
    id: Type.Number(),
    name: Type.String(),
    description: Type.Optional(Type.String()),
  }),
  defaultSpecies: Type.Object({
    id: Type.Number(),
    name: Type.String(),
  }),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
type DefaultSelectionSchema = Static<typeof DefaultSelectionSchema>;

export const DefaultSelections = createApiModel({
  name: "Default Selections",
  resource: "/default-selections",
  schema: DefaultSelectionSchema,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DefaultSelections = Static<typeof DefaultSelections.schema>;
