import { request } from "@tracktile/axiom";
import { useState } from "react";
import Toast from "react-native-root-toast";

import { useApi, DefaultSelections, Species, Deduction } from "../api";
import { getConfig } from "../config";
import { useStorage } from "../lib/storage";

export const useDefaultSelections = () => {
  const api = useApi();
  const [token] = useStorage<string>("token");
  const apiUrl = getConfig().api;

  const toastOptions = {
    duration: Toast.durations.LONG,
    position: -150,
  };

  const { data: existingDefaultSelections } = api.DefaultSelections.call({});

  const [defaultSelections, setDefaultSelectionsState] =
    useState<DefaultSelections>({
      defaultDeduction: {
        id: 0,
        name: "",
        description: "",
      },
      defaultSpecies: {
        id: 0,
        name: "",
      },
      ...existingDefaultSelections,
    });

  const setDefaultSelections = (
    stateFn: (state: DefaultSelections) => DefaultSelections
  ) => {
    setDefaultSelectionsState(stateFn(defaultSelections));
  };

  const { defaultDeduction, defaultSpecies } = defaultSelections;

  const setDefaultSpecies = async (species: Species) => {
    const newSelection = {
      ...defaultSelections,
      defaultSpecies: {
        id: parseInt(species.id, 10),
        name: species.name,
      },
    };
    setDefaultSelections(() => newSelection);
    await updateDefaultSelections(newSelection);
  };

  const setDefaultDeduction = async (deduction: Deduction) => {
    const newSelection = {
      ...defaultSelections,
      defaultDeduction: {
        id: deduction.itemId ?? 0,
        name: deduction.itemName ?? "",
        description: deduction.itemDescription ?? "",
      },
    };

    setDefaultSelections(() => newSelection);
    await updateDefaultSelections(newSelection);
  };

  const updateDefaultSelections = async (newSelection: DefaultSelections) => {
    try {
      await request<DefaultSelections>(`${apiUrl}/default-selections`, {
        method: "PUT",
        token,
        body: newSelection,
      });

      Toast.show(`Default Selection Updated`, toastOptions);
      api.DefaultSelections.invalidateAll();
    } catch (err) {
      console.error(err);
      Toast.show("Failed to update Default Selections");
    }
  };

  return {
    defaultSelections,
    defaultSpecies,
    defaultDeduction,
    setDefaultSpecies,
    setDefaultDeduction,
  };
};
