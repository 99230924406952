import { createApiModel, Type, Static } from "@tracktile/axiom";

import { useApi } from "..";

export const Node = Type.Object({
  id: Type.String({ format: "uuid" }),
  name: Type.String(),
  behaviour: Type.Object({
    type: Type.String(),
  }),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Node = Static<typeof Node>;

const FlowSchema = Type.Object({
  id: Type.String({ format: "uuid" }),
  nodes: Type.Array(Node),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
type FlowSchema = Static<typeof FlowSchema>;

export const TracktileFlow = createApiModel({
  name: "Tracktile Flow",
  resource: "/integration/tracktile/flow",
  schema: FlowSchema,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TracktileFlow = Static<typeof FlowSchema.schema>;

export const useTracktileFlow = () => {
  const api = useApi();
  const { data: flow, ...queryMethods } = api.TracktileFlow.call({});
  return { flow, ...queryMethods };
};
