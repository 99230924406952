import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
} from "@fortawesome/react-native-fontawesome";
import { FC } from "react";
import { ImageProps, ImageStyle, StyleProp, View } from "react-native";

export interface IconProps extends Partial<ImageProps> {
  size?: number;
  color?: string;
  icon: IconProp;
  rotate?: number;
  style?: StyleProp<any>;
}

export const Icon: FC<IconProps> = ({
  size = 24,
  icon,
  color = "#000",
  rotate = 0,
  style: s = {},
}) => {
  const style = s as {
    tintColor?: string;
    marginHorizontal?: number;
    marginLeft?: number;
    marginRight?: number;
  };

  return (
    <View
      style={{
        marginHorizontal: style?.marginHorizontal ?? 0,
        marginLeft: style?.marginLeft,
        marginRight: style?.marginRight,
      }}
    >
      <FontAwesomeIcon
        size={size}
        icon={icon}
        color={style?.tintColor ?? color}
        style={
          {
            transform: [{ rotate: `${rotate}deg` }],
            outline: "none",
            outlineStyle: "none",
            fill: style?.tintColor ?? color,
          } as FontAwesomeIconStyle
        }
      />
    </View>
  );
};
