import { Layout, Button, Divider } from "@ui-kitten/components";
import { useMemo } from "react";
import { ScrollView } from "react-native";

import { RSBaitQuantity } from "../api";

interface SpeciesSelectionModalProps {
  quantities?: RSBaitQuantity[];
  onSelect: (quantity: RSBaitQuantity) => void;
}

export const QuantitySelectionModal = ({
  quantities = [],
  onSelect,
}: SpeciesSelectionModalProps) => {
  const handleQuantitySelection = (quantity: RSBaitQuantity) => {
    onSelect(quantity);
  };

  const sortedQuantities = useMemo(() => {
    return quantities
      .sort(({ value: a }, { value: b }) => (a < b ? -1 : a > b ? 1 : 0))
      .sort(({ unit: a, unit: b }) => (a < b ? 1 : a > b ? -1 : 0));
  }, [quantities]);

  return (
    <Layout style={{ flex: 1, flexDirection: "column" }}>
      <ScrollView style={{ paddingVertical: 20, paddingHorizontal: 10 }}>
        {sortedQuantities
          .filter(({ enabled }) => !!enabled)
          .map(({ value, unit, counterPointId, enabled, price }) => (
            <Layout
              key={`${value}-${unit}-${counterPointId}`}
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingVertical: 6,
              }}
            >
              <Button
                style={{ flex: 1 }}
                size="large"
                appearance="outline"
                status="primary"
                onPress={() =>
                  handleQuantitySelection({
                    value,
                    unit,
                    counterPointId,
                    enabled,
                    price,
                  })
                }
              >
                {`${value} ${unit.toUpperCase()}`}
              </Button>
              <Divider />
            </Layout>
          ))}
      </ScrollView>
    </Layout>
  );
};
