import { faArrowLeft, faBars } from "@fortawesome/pro-regular-svg-icons";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import {
  DrawerActions,
  NavigationProp,
  ParamListBase,
  useNavigationState,
} from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import {
  TopNavigation as KittenTopNav,
  TopNavigationAction,
  TopNavigationProps as KittenTopNavProps,
  StyleService,
} from "@ui-kitten/components";
import { ReactElement } from "react";
import { Platform, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import { Icon } from "./Icon";
import { useNetworkContext } from "../context/networkProvider";

const styles = StyleService.create({
  circle: {
    width: 22,
    height: 22,
    borderRadius: 22,
  },
});

interface TopNavigationProps extends Partial<KittenTopNavProps> {
  navigation:
    | NativeStackNavigationProp<ParamListBase, string>
    | DrawerNavigationProp<ParamListBase, string>
    | NavigationProp<ParamListBase, string>;
  left?: () => ReactElement;
  right?: () => ReactElement;
  showMenuAction?: boolean;
  enableBackAction?: boolean;
}

export const TopNavigation = ({
  navigation,
  title,
  left,
  right,
  showMenuAction = false,
  enableBackAction = false,
}: TopNavigationProps) => {
  const { isOnline } = useNetworkContext();
  const { routeNames, index } = useNavigationState((state) => state);

  const defaultLeft = () => {
    if (enableBackAction && navigation.canGoBack()) {
      return (
        <TopNavigationAction
          icon={(props) => <Icon {...props} icon={faArrowLeft} />}
          onPress={() => navigation.goBack()}
        />
      );
    }
    if (showMenuAction) {
      return (
        <TopNavigationAction
          icon={(props) => <Icon {...props} icon={faBars} />}
          onPress={() => {
            navigation.dispatch(DrawerActions.openDrawer());
          }}
        />
      );
    }
    return <></>;
  };

  const defaultRight = () => {
    return (
      <>
        {Platform.OS !== "web" && (
          <View
            style={[
              styles.circle,
              {
                backgroundColor: isOnline ? "#51F1B0" : "#FF3D71",
                marginRight: 10,
              },
            ]}
          />
        )}
      </>
    );
  };

  return (
    <SafeAreaView edges={["right", "left", "top"]}>
      <KittenTopNav
        shouldRasterizeIOS
        alignment="center"
        title={title ?? routeNames[index]}
        accessoryLeft={left || defaultLeft}
        accessoryRight={right || defaultRight}
        style={{}}
      />
    </SafeAreaView>
  );
};
