import {
  faAdd,
  faCalendar,
  faCancel,
  faCorner,
  faMapPin,
  faPerson,
  faPlusCircle,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { useFocusEffect } from "@react-navigation/native";
import {
  Layout,
  Button,
  Card,
  Datepicker,
  useTheme,
  Text,
} from "@ui-kitten/components";
import { useState, FC, useMemo, useEffect, useCallback } from "react";
import { ScrollView, Platform, View } from "react-native";

import { OrderStackScreenProps } from "./types";
import { useApi } from "../../api";
import { RSBaitQuantity, RSBaitSpecies } from "../../api/models/royalstar";
import {
  CountInput,
  FisherSelectionModal,
  SpeciesSelectionModal,
  QuantitySelectionModal,
  TopNavigation,
  Select,
  Icon,
} from "../../components";
import { useBaitLocation } from "../../context/baitLocationProvider";
import { useBaitOrder } from "../../context/royalstar/baitOrderProvider";
import { useConfirmation, useModal, Modal } from "../../hooks";

interface BaitOrderRowProps {
  itemId: string;
  species: Pick<RSBaitSpecies, "id" | "name">;
  quantity?: RSBaitQuantity;
  count: number;
  showSpeciesSelectionModal: () => void;
  showQuantitySelectionModal: () => void;
  setCount: (count: number) => void;
  onRemove: () => void;
}

export const BaitOrderRow: FC<BaitOrderRowProps> = ({
  itemId,
  species,
  quantity,
  count,
  showSpeciesSelectionModal,
  showQuantitySelectionModal,
  setCount,
  onRemove,
}: BaitOrderRowProps) => {
  const theme = useTheme();

  return (
    <Card style={{ marginBottom: 20 }}>
      <Layout
        style={{
          paddingVertical: 10,
          flexDirection: "row",
          marginHorizontal: 0,
          alignItems: "center",
        }}
      >
        <Layout>
          <Button
            size="giant"
            appearance={!species ? "outline" : "ghost"}
            onPress={showSpeciesSelectionModal}
          >
            {(species && species.name) || "Select Species"}
          </Button>
        </Layout>
        <Layout style={{ flex: 1 }} />
        {species && (
          <Layout>
            <Button
              size="giant"
              appearance={
                !!quantity?.unit && !!quantity?.value ? "ghost" : "filled"
              }
              onPress={showQuantitySelectionModal}
            >
              {!!quantity?.unit && !!quantity?.value
                ? `${quantity.value} ${quantity.unit}`
                : "Choose Size"}
            </Button>
          </Layout>
        )}
        {!!quantity?.unit && !!quantity?.value && (
          <Layout style={{ paddingLeft: 20, paddingRight: 10 }}>
            <CountInput value={count} onChange={setCount} />
          </Layout>
        )}
        <Layout>
          <Button
            appearance="ghost"
            status="danger"
            accessoryLeft={(props) => <Icon {...props} icon={faTrash} />}
            onPress={() => onRemove()}
          />
        </Layout>
      </Layout>
    </Card>
  );
};

interface BaitOrderMockRowProps {
  onNewItem: () => void;
}

const BaitOrderMockRow = ({ onNewItem }: BaitOrderMockRowProps) => (
  <Card key="new" style={{ marginBottom: 20 }}>
    <Layout
      style={{
        paddingVertical: 10,
        flexDirection: "row",
        marginHorizontal: 0,
        alignItems: "center",
      }}
    >
      <Layout>
        <Button
          size="large"
          appearance="filled"
          onPress={onNewItem}
          accessoryLeft={(props) => <Icon {...props} icon={faPlusCircle} />}
        >
          Add Species
        </Button>
      </Layout>
    </Layout>
  </Card>
);

const RealOrderScreen = ({ navigation, route }: OrderStackScreenProps) => {
  const api = useApi();
  const orderId = (route.params as { orderId?: string })?.orderId;
  const { locations } = useBaitLocation();

  const {
    date,
    fisher,
    items,
    addItem,
    setDate,
    setFisher,
    setSpeciesForItem,
    setQuantityForItem,
    setCountForItem,
    locationKey,
    setLocationKey,
    removeItem,
    resetOrder,
    canCancelOrder,
    canCheckout,
    submitOrder,
    deleteOrder,
  } = useBaitOrder(orderId);

  const { data: fishers = [] } = api.RSBaitFisher.all();
  const { data: species = [] } = api.RSBaitSpecies.all();

  const [speciesSelectionTargetId, setSpeciesSelectionTargetId] =
    useState<string>();
  const [quantitySelectionTargetId, setQuantitySelectionTargetId] =
    useState<string>();

  const activeItemQuantities = useMemo(() => {
    const { species: { id } = { id: "" } } =
      items.find((item) => item.id === quantitySelectionTargetId) ?? {};
    return species.find(({ id: _id }) => _id === id)?.quantities;
  }, [items, quantitySelectionTargetId]);

  const { Modal: CancelConfirmationModal, show: showCancelModal } =
    useConfirmation({
      title: "Cancel Order?",
      description: "Order will not be saved.",
      cancelText: "Go Back",
      confirmText: "Cancel Order",
      onConfirm: () => {
        resetOrder();
        if (typeof orderId !== "undefined") {
          navigation.navigate("Orders");
        }
        api.RSBaitOrder.invalidateAll();
      },
    });

  const { Modal: DeleteConfirmationModal, show: showDeleteModal } =
    useConfirmation({
      title: "Delete Order?",
      description: "Once deleted, order cannot be recovered.",
      cancelText: "Go Back",
      confirmText: "Delete Order",
      onConfirm: () => {
        deleteOrder();
        api.RSBaitOrder.invalidateAll();
        navigation.navigate("Orders");
      },
    });

  const {
    modalProps: fisherSelectionModalProps,
    toggle: toggleFisherSelectionModal,
  } = useModal({
    title: "Select Fisher",
  });

  const {
    toggle: toggleSpeciesSelectionModal,
    modalProps: speciesSelectionModalProps,
  } = useModal({
    title: "Select Bait",
  });

  const {
    toggle: toggleQuantitySelectionModal,
    modalProps: quantitySelectionModalProps,
  } = useModal({
    title: "Select Quantity",
  });

  const handleFisherSelection = ([counterPointId]: (string | number)[]) => {
    const fisher = fishers.find(
      ({ counterPointId: cId }) => cId === counterPointId
    );
    if (!fisher) {
      return;
    }
    setFisher(fisher);
    toggleFisherSelectionModal();
  };

  const handleSpeciesSelection = (species: RSBaitSpecies, itemId?: string) => {
    if (itemId) {
      setSpeciesForItem(species, itemId);

      if (species.quantities.length === 1) {
        setQuantityForItem(species.quantities[0], itemId);
      }
    } else {
      if (species.quantities.length === 1) {
        addItem(species, species.quantities[0]);
      } else {
        addItem(species);
      }
    }
    toggleSpeciesSelectionModal();
  };

  const handleQuantitySelection = (
    quantity: RSBaitQuantity,
    itemId?: string
  ) => {
    if (!itemId) return;
    setQuantityForItem(quantity, itemId);
    toggleQuantitySelectionModal();
  };

  useEffect(() => {
    if (typeof orderId === "undefined") {
      toggleFisherSelectionModal();
    }
  }, []);

  return (
    <Layout level="4" style={{ flexGrow: 1 }}>
      <TopNavigation
        title={orderId ? "Edit Order" : "New Order"}
        navigation={navigation}
        showMenuAction
      />
      <Card
        style={{ flex: 0, paddingHorizontal: 20, marginTop: 0, minHeight: 86 }}
      >
        <Layout
          style={{
            paddingVertical: 0,
            paddingHorizontal: 0,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            size="large"
            appearance={!fisher ? "filled" : "ghost"}
            onPress={() => toggleFisherSelectionModal()}
            accessoryLeft={(props) => (
              <Icon {...props} icon={fisher ? faPerson : faAdd} />
            )}
          >
            <View
              style={{
                flexDirection: "column",
                backgroundColor: "rgba(0,0,0,0)",
              }}
            >
              {typeof fisher?.companyName === "string" && (
                <Text
                  appearance={fisher ? "default" : "alternative"}
                  category="s1"
                >
                  {`${fisher.companyName}`}
                </Text>
              )}
              <Text
                appearance={fisher ? "default" : "alternative"}
                category={fisher?.companyName ? "s2" : "s1"}
              >
                {fisher
                  ? `${fisher.firstName} ${fisher.lastName}`
                  : "Select Fisher"}
              </Text>
            </View>
          </Button>
          <Layout style={{ flex: 1 }} />
          {Platform.OS === "web" && (
            <>
              <Select
                key={orderId}
                accessoryLeft={<Icon icon={faMapPin} />}
                options={locations.map((loc) => ({
                  label: loc.name,
                  value: loc.key,
                }))}
                value={[locationKey]}
                onSelect={([location]) => {
                  if (location) {
                    setLocationKey(location);
                  }
                }}
              />
              <Layout style={{ flex: 0, marginHorizontal: 12 }} />
            </>
          )}
          <Datepicker
            status="info"
            size="large"
            date={new Date(date)}
            onSelect={(date) => setDate(date)}
            accessoryLeft={(props) => <Icon {...props} icon={faCalendar} />}
            disabled={Platform.OS !== "web"}
          />
          {orderId && (
            <Button
              appearance="outline"
              status="danger"
              style={{ marginLeft: 18 }}
              onPress={() => showDeleteModal()}
              accessoryLeft={(props) => <Icon {...props} icon={faTrash} />}
            />
          )}
        </Layout>
      </Card>
      <ScrollView style={{ flexGrow: 1, padding: 20 }}>
        {items.map((item) => (
          <BaitOrderRow
            key={item.id}
            itemId={item.id}
            species={item.species}
            quantity={item.quantity}
            count={item.count ?? 0}
            showSpeciesSelectionModal={() => {
              setSpeciesSelectionTargetId(item.id);
              toggleSpeciesSelectionModal();
            }}
            showQuantitySelectionModal={() => {
              setQuantitySelectionTargetId(item.id);
              toggleQuantitySelectionModal();
            }}
            setCount={(count) => {
              setCountForItem(count, item.id);
            }}
            onRemove={() => {
              removeItem(item.id);
            }}
          />
        ))}
        <BaitOrderMockRow
          onNewItem={() => {
            toggleSpeciesSelectionModal();
          }}
        />
      </ScrollView>
      <Layout
        style={{
          flexDirection: "row",
          flexGrow: 0,
          minHeight: 42,
        }}
      >
        <Layout
          style={{
            flex: 1,
          }}
        >
          {canCancelOrder && (
            <Button
              size="giant"
              status="danger"
              accessoryLeft={(props) => <Icon {...props} icon={faCancel} />}
              onPress={() => showCancelModal()}
            >
              Cancel
            </Button>
          )}
          {!canCancelOrder && (
            <Button
              size="giant"
              accessoryLeft={(props) => <Icon {...props} icon={faCorner} />}
              onPress={() => {
                resetOrder();
                navigation.navigate("Orders");
              }}
            >
              Go Back
            </Button>
          )}
        </Layout>
        <Layout
          style={{
            flex: 1,
          }}
        >
          <Button
            size="giant"
            accessoryLeft={(props) => <Icon {...props} icon={faPlusCircle} />}
            disabled={!canCheckout}
            onPress={() => {
              submitOrder();
              if (Platform.OS === "web") {
                navigation.navigate("Orders");
              }
            }}
          >
            {orderId ? "Update Order" : "Submit Order"}
          </Button>
        </Layout>
      </Layout>
      <Modal {...fisherSelectionModalProps}>
        <FisherSelectionModal onSelect={handleFisherSelection} />
      </Modal>
      <Modal {...speciesSelectionModalProps}>
        <SpeciesSelectionModal onSelect={handleSpeciesSelection} />
      </Modal>
      <Modal {...quantitySelectionModalProps}>
        <QuantitySelectionModal
          quantities={activeItemQuantities}
          onSelect={(quantity) =>
            handleQuantitySelection(quantity, quantitySelectionTargetId)
          }
        />
      </Modal>
      <CancelConfirmationModal />
      <DeleteConfirmationModal />
    </Layout>
  );
};

export const BaitOrderScreen = ({
  navigation,
  route,
}: OrderStackScreenProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useFocusEffect(
    useCallback(() => {
      setIsVisible(true);

      return () => {
        setIsVisible(false);
      };
    }, [])
  );

  return (
    <>
      {isVisible && <RealOrderScreen navigation={navigation} route={route} />}
    </>
  );
};
