import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  createContext,
  useContext,
  useRef,
  useState,
  useEffect,
  PropsWithChildren,
} from "react";
import { Platform } from "react-native";

const NAMESPACE = "SBM_" as const;

export const prefixKey = (key: string) => `${NAMESPACE}${key}`;

export const removePrefix = (key: string) => key.replace(NAMESPACE, "");

class AsyncStorageWrapper {
  async getAllKeys() {
    return AsyncStorage.getAllKeys();
  }

  async getItem(key: string): Promise<string> {
    const prefixedKey = prefixKey(key);
    const item = await AsyncStorage.getItem(prefixedKey);
    if (!item) {
      return '""';
    }
    return item;
  }
  async setItem(key: string, value?: string) {
    const prefixedKey = prefixKey(key);
    if (typeof value === "undefined") {
      await AsyncStorage.removeItem(prefixedKey);
    } else {
      await AsyncStorage.setItem(prefixedKey, value);
    }
  }
  async clearItem(key: string) {
    const prefixedKey = prefixKey(key);
    await AsyncStorage.removeItem(prefixedKey);
  }
}
export type Listener<EventType> = (event: EventType) => void;

class LocalStorageWrapper {
  async getAllKeys(): Promise<string[]> {
    return Object.keys(localStorage);
  }

  async getItem(key: string): Promise<string> {
    const prefixedKey = prefixKey(key);
    const item = window.localStorage.getItem(prefixedKey);
    if (!item) {
      return '""';
    }
    return item;
  }
  async setItem(key: string, value?: string) {
    const prefixedKey = prefixKey(key);
    if (typeof value === "undefined") {
      window.localStorage.removeItem(prefixedKey);
    } else {
      window.localStorage.setItem(prefixedKey, value);
    }
  }
  async clearItem(key: string) {
    const prefixedKey = prefixKey(key);
    window.localStorage.removeItem(prefixedKey);
  }
}

export const Storage =
  Platform.OS !== "web" ? new AsyncStorageWrapper() : new LocalStorageWrapper();

export type ObserverReturnType<KeyType, EventType> = {
  subscribe: (entryKey: KeyType, listener: Listener<EventType>) => () => void;
  publish: (entryKey: KeyType, event: EventType) => void;
};

export function createObserver<
  KeyType extends string | number | symbol,
  T,
>(): ObserverReturnType<KeyType, T> {
  const listeners: Record<KeyType, Listener<T>[]> = {} as Record<
    KeyType,
    Listener<T>[]
  >;
  return {
    subscribe: (entryKey: KeyType, listener: Listener<T>) => {
      if (!listeners[entryKey]) listeners[entryKey] = [];
      listeners[entryKey].push(listener);
      return () => {
        listeners[entryKey].splice(listeners[entryKey].indexOf(listener), 1);
      };
    },
    publish: (entryKey: KeyType, event: T) => {
      if (!listeners[entryKey]) listeners[entryKey] = [];
      listeners[entryKey].forEach((listener: Listener<T>) => listener(event));
    },
  };
}

export const UseStorageObserver = createObserver<string, string>();

type TStoragePreloadContext = {
  isReady: boolean;
  initialValues: Record<string, any>;
};

const StoragePreloadContext = createContext<TStoragePreloadContext>({
  isReady: false,
  initialValues: {},
});

export function StoragePreloader({ children }: PropsWithChildren<object>) {
  const [isReady, setIsReady] = useState(false);
  const [initialValues, setInitialValues] = useState<Record<string, any>>({});
  useEffect(() => {
    Storage.getAllKeys().then(async (keys) => {
      const initialValues: Record<string, any> = {};
      for (const key of keys) {
        initialValues[removePrefix(key)] = JSON.parse(
          await Storage.getItem(removePrefix(key))
        );
      }
      setInitialValues(initialValues);
      setIsReady(true);
    });
  }, []);

  return (
    <StoragePreloadContext.Provider value={{ isReady, initialValues }}>
      {children}
    </StoragePreloadContext.Provider>
  );
}

export const useStoragePreload = () => useContext(StoragePreloadContext);

export function useStorage<T>(
  key: string,
  initialValue?: T
): [T | undefined, (value?: T) => void] {
  const hookId = useRef(Math.random().toString(36).substr(2, 9));
  const { initialValues } = useStoragePreload();
  const [storedValue, setStoredValue] = useState(
    initialValues?.[key] ?? initialValue
  );

  const syncValue = async () => {
    await Storage.getItem(key)
      .then((value) => {
        if (value === null) {
          return initialValue;
        }
        return JSON.parse(value);
      })
      .then(setStoredValue);
  };

  const setValue = (value: any) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    setStoredValue(valueToStore);
    Storage.setItem(key, JSON.stringify(valueToStore)).finally(() => {
      syncValue();
    });
    UseStorageObserver.publish(key, valueToStore);
  };

  useEffect(() => {
    UseStorageObserver.subscribe(key, () => {
      syncValue();
    });
    syncValue();
  }, []);

  return [storedValue, setValue];
}
