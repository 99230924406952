import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Layout, Button } from "@ui-kitten/components";
import { ScrollView } from "react-native";
import Toast from "react-native-root-toast";
import { SafeAreaView } from "react-native-safe-area-context";

import { RootStackParamList } from "../../types";
import { TopNavigation } from "../components";
import { useStations } from "../context/stationsProvider";

export function StationSelectionScreen({
  navigation,
}: NativeStackScreenProps<RootStackParamList>) {
  const { stations, currentStation, setCurrentStation } = useStations();
  const toastOptions = {
    duration: Toast.durations.LONG,
    position: -150,
  };
  return (
    <>
      <TopNavigation
        navigation={navigation}
        title="Change Station"
        enableBackAction
      />
      <SafeAreaView edges={["bottom"]}>
        <ScrollView>
          <Layout style={{ flex: 1, padding: 24 }}>
            {stations.map((station) => (
              <Button
                key={station.id}
                disabled={station.name === currentStation?.name}
                size="giant"
                style={{ marginVertical: 18 }}
                onPress={() => {
                  setCurrentStation(station);
                  Toast.show(
                    `Station changed to ${station.name}`,
                    toastOptions
                  );
                  navigation.goBack();
                  // navigation.navigate("Landings");
                }}
              >
                {station.name}
              </Button>
            ))}
          </Layout>
        </ScrollView>
      </SafeAreaView>
    </>
  );
}
