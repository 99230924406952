import { ErrorMessage } from "@hookform/error-message";
import {
  Input,
  InputProps,
  Button,
  ButtonProps,
  Text,
  Layout,
} from "@ui-kitten/components";
import { FC } from "react";
import {
  Controller,
  useFormContext,
  RegisterOptions,
  FieldValues,
  SubmitHandler,
} from "react-hook-form";

interface ControlledInputProps extends InputProps {
  name: string;
  label?: string;
  rules?: Omit<
    RegisterOptions<FieldValues>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  showFieldError?: boolean;
}

export const ControlledInput: FC<ControlledInputProps> = ({
  name,
  label,
  rules = {},
  showFieldError = true,
  ...inputProps
}: ControlledInputProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <Layout
          style={{ flexDirection: "column", marginBottom: 18, flexGrow: 1 }}
        >
          <Input
            style={{ flexGrow: 1 }}
            label={label || name}
            status={name in errors ? "danger" : "basic"}
            {...inputProps}
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
          />
          {showFieldError && (
            <ErrorMessage
              errors={errors}
              name={name}
              as={<Text status="danger" />}
            />
          )}
        </Layout>
      )}
    />
  );
};

export function ControlledSubmitButton<T extends Record<string, unknown>>({
  children,
  onSubmit,
  ...props
}: ButtonProps & { onSubmit: SubmitHandler<T> }) {
  const { watch, handleSubmit } = useFormContext<T>();
  return (
    <Button {...props} onPress={handleSubmit(onSubmit)}>
      {children}
    </Button>
  );
}
