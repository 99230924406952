import { createApiModel, Type, Static } from "@tracktile/axiom";

export const User = createApiModel({
  name: "users",
  resource: "/users",
  schema: Type.Object({
    id: Type.String(),
    sbmDomain: Type.String(),
    sbmAccountId: Type.Number(),
    email: Type.String(),
    sbmUserId: Type.Optional(Type.Number()),
    appUserId: Type.Optional(Type.String()),
    firstName: Type.String(),
    lastName: Type.String(),
    enabled: Type.Boolean(),
  }),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type User = Static<typeof User.schema>;
