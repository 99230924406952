import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { BaitOrderScreen } from "./BaitOrderScreen";
import { BaitOrdersScreen } from "./BaitOrdersScreen";
import type { OrdersStackParamsList } from "./types";

export const OrdersStackNavigator =
  createNativeStackNavigator<OrdersStackParamsList>();

export function BaitOrderNavigator() {
  return (
    <OrdersStackNavigator.Navigator initialRouteName="Orders">
      <OrdersStackNavigator.Screen
        component={BaitOrderScreen}
        name="Order"
        options={{ headerShown: false }}
      />
      <OrdersStackNavigator.Screen
        component={BaitOrdersScreen}
        name="Orders"
        options={{ headerShown: false }}
      />
    </OrdersStackNavigator.Navigator>
  );
}
